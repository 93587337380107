/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import type { DegreeWebinar_DegreeWebinar as DegreeWebinar } from '__generated__/graphql-types-do-not-use';

import { Button, Grid, Typography, Typography2, breakpoints } from '@coursera/cds-core';

import Container from 'bundles/premium-hub/components/shared/Container';
import WebinarCard from 'bundles/premium-hub/components/shared/WebinarCard';

import _t from 'i18n!nls/premium-hub';

type WebinarsSectionProps = {
  pastWebinars: DegreeWebinar[];
  upcomingWebinars: DegreeWebinar[];
};

const styles = {
  title: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  subTitle: css`
    margin-bottom: var(--cds-spacing-400);
  `,
  webinarsList: css`
    margin-bottom: var(--cds-spacing-200);
  `,
  showMoreBtn: css`
    margin-bottom: var(--cds-spacing-400);

    ${breakpoints.down('sm')} {
      width: 100%;
      max-width: none;
    }
  `,
};

const INITIAL_WEBINARS_COUNT = 3;
const MAX_WEBINARS_COUNT = 15;

export const WebinarsSection = ({ pastWebinars, upcomingWebinars }: WebinarsSectionProps) => {
  const combinedWebinars = [...(upcomingWebinars || []), ...(pastWebinars || [])];
  const allWebinars = combinedWebinars.slice(0, MAX_WEBINARS_COUNT);
  const initialWebinars = allWebinars.slice(0, INITIAL_WEBINARS_COUNT);
  const additionalWebinars = allWebinars.slice(INITIAL_WEBINARS_COUNT);
  const [showAllWebinars, setShowAllWebinars] = useState(false);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const firstInitialWebinarRef = useRef<HTMLAnchorElement | null>(null);
  const firstAdditionalWebinarRef = useRef<HTMLAnchorElement | null>(null);

  const handleToggleAll = () => {
    const isShowingMore = !showAllWebinars;

    setShowAllWebinars(isShowingMore);
    if (!isShowingMore) {
      // Scroll to the top of the section when showing less webinars
      titleRef?.current?.scrollIntoView({ behavior: 'smooth' });
      firstInitialWebinarRef?.current?.focus();
    }
  };

  useEffect(() => {
    if (showAllWebinars) {
      firstAdditionalWebinarRef?.current?.focus();
    }
  }, [showAllWebinars, firstAdditionalWebinarRef]);

  if (allWebinars.length === 0) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h1semibold" component="h2" css={styles.title} ref={titleRef}>
        {_t('Register for degree program webinars')}
      </Typography>
      <Typography2 component="p" css={styles.subTitle}>
        {_t(
          'Join an upcoming webinar to gain valuable insights from university admissions counselors, instructors, and faculty.'
        )}
      </Typography2>
      <Grid container spacing={24} css={styles.webinarsList}>
        {initialWebinars.map((webinar, idx) => (
          <Grid item xs={12} md={4} key={webinar.id}>
            <WebinarCard
              key={webinar.id}
              webinar={webinar}
              refAlt={
                idx === 0
                  ? (ref: HTMLAnchorElement | null): void => {
                      firstInitialWebinarRef.current = ref;
                    }
                  : undefined
              }
            />
          </Grid>
        ))}
        {showAllWebinars && (
          <>
            {additionalWebinars.map((webinar, idx) => (
              <Grid item xs={12} md={4} key={webinar.id}>
                <WebinarCard
                  key={webinar.id}
                  webinar={webinar}
                  refAlt={
                    idx === 0
                      ? (ref: HTMLAnchorElement | null): void => {
                          firstAdditionalWebinarRef.current = ref;
                        }
                      : undefined
                  }
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
      {additionalWebinars.length > 0 && (
        <Button variant="secondary" onClick={handleToggleAll} css={styles.showMoreBtn}>
          {_t('View #{additionalWebinarsCount} #{buttonAction}', {
            additionalWebinarsCount: additionalWebinars.length,
            buttonAction: showAllWebinars ? 'less' : 'more',
          })}
        </Button>
      )}
    </Container>
  );
};

export default React.memo(WebinarsSection);
