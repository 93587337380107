import React from 'react';

import { useFocusRing } from '@react-aria/focus';
import { useOption } from '@react-aria/listbox';
import { getItemCount } from '@react-stately/collections';
import type { ListState } from '@react-stately/list';
import type { Node } from '@react-types/shared';
import clsx from 'clsx';

import { useLocalizedStringFormatter, mergeProps } from '@coursera/cds-common';
import { CheckIcon } from '@coursera/cds-icons';

import CheckboxCheckedIcon from '@core/forms/CheckboxInput/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '@core/forms/CheckboxInput/CheckboxUncheckedIcon';
import i18nMessages from '@core/forms/i18n';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import getMultiSelectOptionCss, { classes } from './styles/optionCss';

export type Props = {
  /**
   * Additional context for an option
   */
  supportText?: string;

  /**
   * Additional element rendered at the end of option element
   */
  suffix?: React.ReactNode;

  /**
   * Node in collection
   * @ignore
   */
  item: Node<unknown>;

  /**
   * ListBox state
   * @ignore
   */
  state: ListState<unknown>;

  /**
   * Search query if available
   */
  searchQuery?: string;

  /**
   * Function used to compare two strings
   */
  comparator?: (textValue: string, inputValue: string) => boolean;
};

/**
 * Renders option
 */
const Option = (props: Props) => {
  const { supportText, suffix, item, state, searchQuery, comparator } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const ref = React.useRef(null);

  // TODO: orest - figure out what is the real cause of the error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore for some reason VSCode is not able to resolve this return type correctly properly
  const { optionProps, isSelected, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  );

  const { isFocusVisible, focusProps } = useFocusRing();

  optionProps['aria-posinset'] =
    typeof item.index !== 'undefined' ? item.index + 1 : undefined;
  optionProps['aria-setsize'] = getItemCount(state.collection);

  const content =
    searchQuery && searchQuery.length > 0 && comparator
      ? getHighlightedText(item.textValue, searchQuery, comparator)
      : item.rendered;

  const selectionMode = state.selectionManager?.selectionMode;
  const listboxHasSelectedItem = state.selectionManager?.selectedKeys?.size > 0;

  return (
    <li
      ref={ref}
      className={clsx(classes.root, {
        [classes.selected]: isSelected,
        [classes.focusVisible]: isFocusVisible,
        [classes.disabled]: isDisabled,
        [classes.singleSelect]: selectionMode === 'single',
      })}
      css={getMultiSelectOptionCss}
      {...mergeProps(optionProps, focusProps)}
    >
      <div className={classes.container}>
        {selectionMode === 'multiple' &&
          (isSelected ? (
            <CheckboxCheckedIcon className={classes.checkboxIcon} />
          ) : (
            <CheckboxUncheckedIcon className={classes.checkboxIcon} />
          ))}
        <div className={classes.content}>
          <Typography className={classes.labelRow} component="div">
            <div aria-hidden className={classes.label}>
              {content}
            </div>
            <VisuallyHidden>{item.rendered} &nbsp;</VisuallyHidden>

            {suffix && <div className={classes.suffix}>{suffix}</div>}

            {selectionMode === 'single' && (
              <div
                className={clsx(classes.selectedIcon, {
                  [classes.hasSelectedItem]: listboxHasSelectedItem,
                })}
              >
                {isSelected && (
                  <CheckIcon
                    size="medium"
                    title={stringFormatter.format('selected')}
                  />
                )}
              </div>
            )}
          </Typography>

          {supportText && (
            <Typography
              className={classes.supportText}
              color="supportText"
              component="div"
              variant="bodySecondary"
            >
              {supportText}
            </Typography>
          )}
        </div>
      </div>
    </li>
  );
};

function getHighlightedText(
  text: string,
  highlight: string,
  compare: (a: string, b: string) => boolean
) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) => (
        <Typography
          key={i}
          component="span"
          variant={compare(part, highlight) ? 'subtitleMedium' : 'inherit'}
        >
          {part}
        </Typography>
      ))}
    </>
  );
}

export default Option;
