import React from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter, breakpoints } from '@coursera/cds-common';

import { SelectOption } from '@core/SelectField';
import SilentSelectField from '@core/SilentSelectField';
import type { SilentSelectFieldProps } from '@core/SilentSelectField';
import { useMediaQuery } from '@core/utils';

import i18nMessages from './i18n';
import MobilePagination from './MobilePagination';
import PaginationItem from './PaginationItem';
import { getPaginationCss, classes } from './styles/paginationCss';
import type { VisualProps } from './styles/paginationCss';
import type { PageAriaLabel, RenderItem } from './types';
import usePagination from './usePagination';

export type PaginationProps = {
  /**
   * Total number of paginated items
   */
  total: number;

  /**
   * The page selected by default.
   * Used for uncontrolled component.
   * @default 1
   */
  defaultPage?: number;

  /**
   * The current page. Use when component is controlled.
   */
  page?: number;

  /**
   * The default number of items displayed per page.
   * Used for uncontrolled component.
   * @default 10
   */
  defaultPageSize?: number;

  /**
   * The number of items displayed per page.
   * Used for controlled component.
   */
  pageSize?: number;

  /**
   * Allows to customize page size options available for selection.
   * @default [10, 25, 50, 100]
   */
  pageSizeInputOptions?: Array<{ label: string; value: number }>;

  /**
   * Allows to customize properties of SilentSelectField.
   * Use it to customize the label.
   */
  pageSizeInputProps?: Partial<SilentSelectFieldProps>;

  /**
   * Accepts a function which returns a string value that provides a user-friendly name for pagination controls.
   * This is important for screen reader users.
   */
  getItemAriaLabel?: PageAriaLabel;

  /**
   * Callback fired when page size is changed.
   * Use in controlled mode (see `pageSize`).
   */
  onPageSizeChange?: (event: React.ChangeEvent<unknown>, size: number) => void;

  /**
   * Callback fired when the page is changed.
   * Use in controlled mode (see `page`).
   */
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void;

  /**
   * Allows to specify alignment of page size input.
   * Effective only for SM and XS breakpoints.
   * @default start
   */
  pageSizeInputAlignment?: VisualProps['pageSizeInputAlignment'];

  /**
   * If set to `true` renders First page and Last page helper navigation.
   * Buttons will be displayed on XS breakpoint only.
   */
  showXsNav?: boolean;

  /**
   * If set to `true` Items per page control will be hidden.
   * @default false
   */
  hidePageSizeInput?: boolean;

  /**
   * Render the pagination item
   * @ignore
   */
  renderItem?: RenderItem;
  /**
   * When value is justified, distribute sub-components evenly on medium and larger breakpoints.
   * The first item, a dropdown for changing page size, will align flush with the main-start edge,
   * while the last item, a list of buttons for changing the current page,
   * will align flush with the main-end edge.
   * @default start
   */
  alignment?: 'start' | 'justified';
  /**
   * Optional additional classes for the root component
   * @ignore
   */
  className?: string;
};

const defaultPageSizeOptions: PaginationProps['pageSizeInputOptions'] = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const defaultRenderItem: RenderItem = (itemProps, ref) => (
  <PaginationItem {...itemProps} ref={ref} data-testid="pagination-item" />
);

/**
 * The Pagination component allows a user to navigate between pages of grouped content in a linear flow.
 *
 * See [Props](__storybookUrl__/components-navigation-pagination--default#props)
 */
const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>(
  function Pagination(props, ref) {
    const {
      total,
      defaultPage = 1,
      page: pageProp,
      pageSize: pageSizeProp,
      defaultPageSize = 10,
      pageSizeInputOptions = defaultPageSizeOptions,
      pageSizeInputProps,
      pageSizeInputAlignment = 'start',
      hidePageSizeInput,
      showXsNav,
      getItemAriaLabel,
      renderItem = defaultRenderItem,
      onPageChange,
      onPageSizeChange,
      alignment = 'start',
      className,
      ...rest
    } = props;

    const css = getPaginationCss({ pageSizeInputAlignment });

    const stringFormatter = useLocalizedStringFormatter(i18nMessages);

    const isSmBreakpoint = useMediaQuery(breakpoints.down('xs'));

    const currentPageRef = React.useRef<HTMLButtonElement>(null);

    const {
      pages,
      currentPage,
      pageSize,
      totalPages,
      firstPage: firstPageControlProps,
      lastPage: lastPageControlProps,
      nextPage: nextPageControlProps,
      previousPage: previousPageControlProps,
      handlePageSizeChange,
    } = usePagination({
      total,
      page: pageProp,
      pageSize: pageSizeProp,
      defaultPage,
      defaultPageSize,
      boundaryCount: 1,
      siblingCount: 1,
      onChange: onPageChange,
      onPageSizeChange,
    });

    const getAriaLabel = React.useCallback<PageAriaLabel>(
      (type, page, selected) => {
        if (getItemAriaLabel) {
          return getItemAriaLabel(type, page, selected);
        }

        if (selected) {
          if (page === totalPages) {
            return `${stringFormatter.format('selected', {
              page: page ?? 1,
            })} , ${stringFormatter.format('lastPage')}`;
          }

          return stringFormatter.format('selected', { page: page ?? 1 });
        }

        if (type === 'page' && page === totalPages) {
          return `${stringFormatter.format('page', {
            page: page ?? 1,
          })}, ${stringFormatter.format('lastPage')}`;
        }

        return stringFormatter.format(type, { page: page ?? 1 });
      },
      [stringFormatter, getItemAriaLabel, totalPages]
    );

    const handleSizeChange = (
      event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
      const { target } = event;
      const valNum = Number(target.value);

      if (!Number.isNaN(valNum)) {
        handlePageSizeChange(event, valNum);
      }
    };

    return (
      <nav
        ref={ref}
        aria-label={stringFormatter.format('pagination')}
        className={clsx(className, {
          [classes.justified]: alignment === 'justified',
        })}
        css={css}
        {...rest}
      >
        {!hidePageSizeInput && (
          <div className={classes.navPageSizeContainer}>
            <SilentSelectField
              label={stringFormatter.format('pageSizeLabel')}
              {...pageSizeInputProps}
              labelPlacement="integrated"
              value={pageSize}
              onChange={handleSizeChange}
            >
              {pageSizeInputOptions.map((option, index) => {
                return (
                  <SelectOption
                    key={`${index}_${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </SelectOption>
                );
              })}
            </SilentSelectField>
          </div>
        )}
        {isSmBreakpoint ? (
          <MobilePagination
            currentPage={currentPage}
            firstPageControlProps={firstPageControlProps}
            getAriaLabel={getAriaLabel}
            lastPageControlProps={lastPageControlProps}
            nextPageControlProps={nextPageControlProps}
            previousPageControlProps={previousPageControlProps}
            renderItem={renderItem}
            showFirstLastControls={showXsNav}
            total={totalPages}
          />
        ) : (
          <ul className={classes.nav}>
            <li className={classes.navControl}>
              {renderItem({
                variant: previousPageControlProps.type,
                selected: previousPageControlProps.selected,
                page: previousPageControlProps.page,
                onClick: previousPageControlProps.onClick,
                disabled: previousPageControlProps.disabled,
                'aria-label': getAriaLabel(
                  previousPageControlProps.type,
                  previousPageControlProps.page,
                  previousPageControlProps.selected
                ),
              })}
            </li>

            {pages?.map((props, index) => {
              return (
                <li key={`${index}-${props.type}-${props.page}`}>
                  {renderItem(
                    {
                      variant: props.type,
                      selected: props.selected,
                      page: props.page,
                      onClick: (
                        event: React.SyntheticEvent<Element, Event>
                      ) => {
                        props.onClick(event);
                        setTimeout(() => {
                          currentPageRef.current?.focus();
                        }, 0);
                      },
                      disabled: props.disabled,
                      'aria-label': getAriaLabel(
                        props.type,
                        props.page,
                        props.selected
                      ),
                    },
                    props.selected ? currentPageRef : undefined
                  )}
                </li>
              );
            })}

            <li className={classes.navControl}>
              {renderItem({
                variant: nextPageControlProps.type,
                selected: nextPageControlProps.selected,
                page: nextPageControlProps.page,
                onClick: nextPageControlProps.onClick,
                disabled: nextPageControlProps.disabled,
                'aria-label': getAriaLabel(
                  nextPageControlProps.type,
                  nextPageControlProps.page,
                  nextPageControlProps.selected
                ),
              })}
            </li>
          </ul>
        )}
      </nav>
    );
  }
);

export default Pagination;
