import * as Sentry from '@sentry/react';
import _ from 'lodash';

import logger from 'js/app/loggerSingleton';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { EOI_ACTIONS } from 'bundles/expression-of-interest/constants';
import { logFlowCompleteAction } from 'bundles/expression-of-interest/utils/util';
import type { ValidationErrors } from 'bundles/premium-hub/types';
import { processResponse } from 'bundles/survey-form/utils/SurveyFormUtils';
import { validateResponse } from 'bundles/survey-form/utils/ValidationUtils';
import useGetSurveyIdByDegreeSlug from 'bundles/university-program-qualification/hooks/useGetSurveyIdByDegreeSlug';
import APIUtils from 'bundles/university-program-qualification/utils/APIUtils';
import { SectionTypes } from 'bundles/university-program-qualification/utils/constants';

const { TERMINAL } = SectionTypes;

type ProgramInfo = {
  slug: string;
  productId: string;
  productType: string;
  onSuccess?: () => void;
};

type SubmitResponseOptions = {
  onSuccess: (responseId?: string) => void;
  onError: () => void;
  logAction?: (action: string, metadata?: Record<string, string>) => void;
};

// ProgramQualificationActions contains actions related to the qualification process
const ProgramQualificationActions = {
  // previewQualificationSurvey fetches and previews the qualification survey for a given program
  previewQualificationSurvey: async (actionContext: $TSFixMe, programInfo: ProgramInfo) => {
    const { slug, productId, productType, onSuccess } = programInfo;

    let correctedSlug = slug;
    let correctedProductId = productId;

    // For EOI 2.0 we need to use MCS-DS instead of MCS for surveys
    // Ticket to fix tech debt: https://coursera.atlassian.net/browse/DEGMKT-352
    if (slug === 'master-of-computer-science-illinois' || productId === 'base~iiNa2dB9T9GJRj_cwwUOIA') {
      correctedSlug = 'masters-in-computer-data-science';
      correctedProductId = 'base~RwwqhYosRMei2WZeEqMYoA';
    }

    let qualificationSurveyProductId: string;
    try {
      // FIXME: React component names must start with an uppercase letter.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const surveyId = correctedProductId ? correctedProductId : useGetSurveyIdByDegreeSlug(correctedSlug);

      qualificationSurveyProductId = tupleToStringKey([productType, surveyId || '']);
      const previewResponse = await APIUtils.previewQualificationSurvey(qualificationSurveyProductId);

      previewResponse.productId = qualificationSurveyProductId;
      actionContext.dispatch('PREVIEW_QUALIFICATION', previewResponse);

      if (onSuccess) onSuccess();
    } catch (error) {
      logger.error(error);
    }
  },

  // updateResponse updates the response in the store
  updateResponse: (actionContext: $TSFixMe, response: unknown) => {
    actionContext.dispatch('UPDATE_RESPONSE', response);
  },

  // submitResponse submits the response and handles success or error cases
  submitResponse: (actionContext: $TSFixMe, { onSuccess, onError, logAction }: SubmitResponseOptions) => {
    const qualificationStore = actionContext.getStore('ProgramQualificationStore');
    const currentResponse = processResponse(qualificationStore.getResponse());

    // Prevent duplicate submissions
    if (qualificationStore.getIsSubmitting()) {
      return;
    }
    let validationErrors: ValidationErrors = {};
    if (qualificationStore.getSectionId !== TERMINAL) {
      validationErrors = validateResponse(qualificationStore.getSectionData(), currentResponse);
    }
    if (_.isEmpty(validationErrors)) {
      logAction?.(EOI_ACTIONS.POST_START);
      actionContext.dispatch('SUBMITTING_QUALIFICATION_RESPONSE');
      const responseId = qualificationStore.getResponseId();
      const sectionId = qualificationStore.getSectionId();

      const trackingFields = qualificationStore.getTrackingFields();
      const productId = qualificationStore.getProductId();

      APIUtils.createOrUpdateQualificationSurvey(productId, sectionId, currentResponse, trackingFields, responseId)
        .then((response) => {
          logFlowCompleteAction(productId, logAction);
          actionContext.dispatch('RECEIVE_SECTION_DATA', response);

          onSuccess?.(response.id);
        })
        .catch((e) => {
          logAction?.(EOI_ACTIONS.POST_ERROR);
          Sentry.captureException(e);
        });
    } else {
      actionContext.dispatch('UPDATE_VALIDATION_ERROR', validationErrors);

      const metadata = {
        hasInvalidPhoneNumber: (!!validationErrors?.phoneNumber).toString(),
        hasInvalidDegreeTrack: (!!validationErrors?.degreeTrack).toString(),
        hasInvalidCategoriesSelection: (!!validationErrors?.categoriesSelection).toString(),
      };

      logAction?.(EOI_ACTIONS.VALIDATION_ERROR, metadata);
      onError?.();
    }
  },

  // previewQualification previews the qualification
  previewQualification: (actionContext: $TSFixMe, response: unknown) => {
    actionContext.dispatch('PREVIEW_QUALIFICATION', response);
  },

  // resetResponse resets the response in the store
  resetResponse: (actionContext: $TSFixMe) => {
    actionContext.dispatch('RESET_RESPONSE');
  },

  // updateValidationError updates the validation errors in the store
  updateValidationError: (actionContext: $TSFixMe, validationErrors: ValidationErrors) => {
    actionContext.dispatch('UPDATE_VALIDATION_ERROR', validationErrors);
  },
};

export default ProgramQualificationActions;
