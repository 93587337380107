/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';

import * as React from 'react';

import { SvgCircleWarningOutline, SvgSuccess } from '@coursera/coursera-ui/svg';

type OwnProps = {
  type?: 'error' | 'success';
  liveAnnounce?: boolean;
};

type Props = OwnProps & Omit<React.ComponentPropsWithoutRef<'div'>, keyof OwnProps>;

const ICON_COLORS = {
  error: '#d30001',
  success: '#1f8354',
};

const ICONS = {
  error: SvgCircleWarningOutline,
  success: SvgSuccess,
};

const bounce = keyframes`
  from {
    max-height: 0;
    opacity: 0;
    transform: scale(1);
  }
  
  10% {
    max-height: 500px;
    opacity: 0;
    transform: scale(0.5);
  }
  
  40% {
    opacity: 1;
    transform: scale(1.05);
  }
  
  70% {
    transform: scale(0.95);
  }
`;

const styles = {
  root: css`
    animation: ${bounce} 350ms ease-out;
    align-items: center;
    border-radius: 4px;
    display: flex;
    padding: 18px 22px 18px 16px;
    width: 100%;
  `,

  icon: css`
    margin-right: 12px;
  `,

  label: css`
    font-size: 12px;
    line-height: 18px;
  `,

  success: css`
    background-color: #ecf4e8;
  `,

  error: css`
    background-color: #fbe6e6;
  `,
};

const Alert = ({ children, type = 'error', liveAnnounce = true, ...props }: Props) => {
  const Icon = ICONS[type] ?? ICONS.error;
  const iconColor = ICON_COLORS[type] ?? ICON_COLORS.error;
  const style = styles[type] ?? styles.error;

  const liveAnnounceProps = { 'aria-live': 'assertive' as const, role: 'alert' };

  return (
    <div css={[styles.root, style]} {...(liveAnnounce ? liveAnnounceProps : {})} {...props}>
      {Icon && (
        <div aria-hidden="true" css={styles.icon}>
          <Icon size={24} color={iconColor} />
        </div>
      )}
      <div css={styles.label}>{children}</div>
    </div>
  );
};

export default Alert;
