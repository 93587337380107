import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('multiSelect', [
  'controllerContent',
  'label',
  'itemsNumber',
]);

const styles = css`
  .${classes.controllerContent} {
    display: flex;
    justify-content: flex-start;
    gap: var(--cds-spacing-50);
    align-items: center;
  }

  .${classes.label} {
    text-align: left;
  }

  .${classes.itemsNumber} {
    min-width: 32px;
    margin-right: var(--cds-spacing-100);
  }
`;

export default styles;
