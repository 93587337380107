/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Checkbox, breakpoints, typography2 } from '@coursera/cds-core';

import { useEnterpriseAdminSearchContext } from 'bundles/enterprise-admin-product-search/context';
import SearchFilterLabelDialog from 'bundles/search-common/components/filters/SearchFilterLabelDialog';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';

export type EventingData = {
  activeFilterItems?: Record<string, string[]>;
  searchIndex: string;
  searchIndexPosition: number;
};

type InputProps = {
  addFilters?: (x0: string) => void;
  removeFilters?: (x0: string) => void;
  eventingData: EventingData;
  filterCategoryName: string;
  count: number;
  isRefined: boolean;
  value: string;
  supportText?: string;
  dialogText?: string;
  label: string | JSX.Element;
  customAriaLabel?: string;
  isCompact?: boolean;
  // Added for SERP Filters Redesign experiment
  shouldAlignResultsRight?: boolean;
};

type Props = InputProps;

const styles = {
  compactMode: css`
    label {
      margin-bottom: 0;
      padding-bottom: 1px !important;
      padding-top: 1px !important;
    }

    :not(.cds-checkboxAndRadio-checked) input[type='checkbox'] + svg {
      color: var(--cds-color-grey-600);
    }
  `,
  filterItem: css`
    .cds-checkboxAndRadio-input,
    .cds-checkboxAndRadio-labelText {
      /* Override default color to black to match design */
      color: var(--cds-color-neutral-primary) !important;
    }

    &.cds-checkboxAndRadio-checked {
      /* stylelint-disable-next-line custom-property-pattern */
      --input-fill--hover: var(--cds-color-neutral-primary);
      /* stylelint-disable-next-line custom-property-pattern */
      --input-fill--active: var(--cds-color-neutral-primary);
    }

    p {
      ${typography2.bodySecondary}
    }
  `,
  filterLabelCount: css`
    color: var(--cds-color-grey-600);
    margin-left: var(--cds-spacing-50);
  `,
  alignResultsRight: css`
    ${breakpoints.up('sm')} {
      float: right;
    }
  `,
  lessMarginBottom: css`
    label {
      margin-bottom: 4px;
    }
  `,
};

export const SearchFilterItem = ({
  label,
  value,
  count,
  isRefined,
  supportText,
  dialogText,
  filterCategoryName,
  addFilters,
  removeFilters,
  eventingData,
  customAriaLabel,
  isCompact,
  shouldAlignResultsRight,
}: Props) => {
  const isEnterpriseAdminSearch = useEnterpriseAdminSearchContext();
  const withSupportTextForFilter = !isEnterpriseAdminSearch;
  const trackComponent = useRetracked();
  const onChange = () => {
    if (isRefined) {
      removeFilters?.(value);
    } else {
      addFilters?.(value);
    }
    trackComponent({
      trackingData: {
        filterOptionName: label,
        value,
        count,
        selectionStatus: !isRefined ? 'select' : 'deselect',
        filterCategoryName,
        indexName: eventingData?.searchIndex,
        indexPosition: eventingData?.searchIndexPosition,
      },
      trackingName: 'filter_option',
      action: 'click',
    });
  };

  // for components like CourseraPlusSearchFilter that render custom label
  const filterLabel = () => {
    if (React.isValidElement(label)) {
      return label;
    } else if (dialogText) {
      return <SearchFilterLabelDialog label={label} count={count} dialogText={dialogText} />;
    } else {
      return (
        <span>
          {label}
          {count > 0 && (
            <span css={[styles.filterLabelCount, shouldAlignResultsRight && styles.alignResultsRight]}>
              ({count.toLocaleString()})
            </span>
          )}
        </span>
      );
    }
  };

  const checkbox = (
    <Checkbox
      checked={isRefined}
      // Needs special handling to determine if the number of results should float right or not
      // @ts-expect-error CDS-Snowflake-Checkbox-label
      label={filterLabel()}
      supportText={withSupportTextForFilter && !isInFilterTestVariant() ? supportText : undefined}
      onChange={onChange}
      css={[styles.filterItem, isCompact && styles.compactMode, isInFilterTestVariant() && styles.lessMarginBottom]}
      {...(customAriaLabel && {
        'aria-label': customAriaLabel,
      })}
      data-testid={`${value}-${isRefined}`}
    />
  );

  return checkbox;
};

export default SearchFilterItem;
