import * as React from 'react';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import SSOError from 'bundles/authentication/shared/components/authentication-modal/SocialSSO/SSOError';
import thirdPartyAuth from 'bundles/third-party-auth/lib';
import Instrumentation from 'bundles/userModal/lib/instrumentation';

type ButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  forwardedRef?: React.Ref<HTMLElement>;
};

type SocialSSOProps = {
  onSuccess?: (isRegistration: boolean) => void;
  onError?: (error: SSOError) => void;
};

type State = {
  connecting: boolean;
};

const HANDLED_ERRORS = ['notAuthorized', 'unknownStatus'];

const withSocialSSO = <Props extends ButtonProps>(
  WrappedComponent: React.ComponentType<Props>,
  serviceName: 'apple' | 'facebook'
): React.ComponentClass<Props & SocialSSOProps, State> => {
  const componentName = WrappedComponent.displayName ?? WrappedComponent.name;

  class SocialSSO extends React.Component<Props & SocialSSOProps, State> {
    static displayName = `withSocialSSO(${componentName})`;

    state = { connecting: false };

    connect = () => {
      if (serviceName !== 'facebook') {
        // FB has some issues and does not notify when popup is blocked
        this.setState({ connecting: true });
      }

      thirdPartyAuth.connect(serviceName).then(this.handleSuccess).catch(this.handleError);
    };

    handleError = (error: $TSFixMe) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const { onError = () => {} } = this.props;

      this.setState({ connecting: false });

      if (!HANDLED_ERRORS.includes(error.code)) {
        Instrumentation.thirdPartyError(serviceName, error.code);
        onError(new SSOError(error, serviceName));
      }
      // else
      // handle close of the modal or click in reject app
      // we silence those errors
    };

    handleSuccess = (response: $TSFixMe) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const { onSuccess = () => {} } = this.props;

      Instrumentation.thirdPartyAuth(serviceName, response);

      this.setState({ connecting: false });

      onSuccess(response.isRegistration);
    };

    render() {
      const { connecting } = this.state;
      const { props } = this;

      return <WrappedComponent {...props} disabled={connecting} onClick={this.connect} ref={props.forwardedRef} />;
    }
  }

  hoistNonReactStatics(SocialSSO, React.Component);

  return SocialSSO;
};

export default withSocialSSO;
