/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import * as Sentry from '@sentry/react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { useRetracked } from 'js/lib/retracked';

import { Link, Typography2 } from '@coursera/cds-core';

import * as actions from 'bundles/authentication/shared/actions';
import type SSOError from 'bundles/authentication/shared/components/authentication-modal/SocialSSO/SSOError';
import usePathname from 'bundles/authentication/shared/hooks/usePathname';
import HorizontalSocialSSO from 'bundles/expression-of-interest/components/authentication/HorizontalSocialSSO';
import {
  ACTION_QUERY_PARAMS,
  EMBEDDED_DEGREE_FORM_TRACKER,
  THIRD_PARTY_AUTH_SERVICES_ENABLED,
} from 'bundles/expression-of-interest/constants';
import useInitialFocusOnSso from 'bundles/expression-of-interest/hooks/useInitialFocusOnSSO';
import Instrumentation from 'bundles/userModal/lib/instrumentation';

import _t from 'i18n!nls/expression-of-interest';

type Props = { trackingProductId: string; shouldShowRecaptchaDisclaimer?: boolean; shouldSetAutoFocus?: boolean };

const styles = {
  container: css`
    section {
      display: flex;
      padding-bottom: 12px;
    }
  `,
  supportText: css`
    margin-top: var(--cds-spacing-150);
  `,
};

export const EoiSSO = ({
  trackingProductId,
  shouldShowRecaptchaDisclaimer = true,
  shouldSetAutoFocus = false,
}: Props) => {
  const { initialFocusOnSSO } = useInitialFocusOnSso();

  const pathname = usePathname();
  const logEvent = useRetracked();

  const onSocialSSOSuccess = (isRegistration: boolean) => {
    Instrumentation.login();

    logEvent({
      action: isRegistration
        ? EMBEDDED_DEGREE_FORM_TRACKER.actions.HANDLE_SUCCESSFUL_SIGNUP
        : EMBEDDED_DEGREE_FORM_TRACKER.actions.HANDLE_SUCCESSFUL_LOGIN,
      trackingName: EMBEDDED_DEGREE_FORM_TRACKER.name,
      trackingData: { pathname },
    });

    const redirectTo = {
      action: ACTION_QUERY_PARAMS.EMAIL_ME_INFO,
    };
    actions.onLogin(redirectTo);
  };

  const onSocialSSOError = (error: SSOError) => {
    Sentry.captureException(error);
    Instrumentation.thirdPartyError(error.service, error);
  };

  return (
    <div>
      <div css={styles.container}>
        <HorizontalSocialSSO
          services={THIRD_PARTY_AUTH_SERVICES_ENABLED}
          onSuccess={onSocialSSOSuccess}
          onError={onSocialSSOError}
          toFocus={shouldSetAutoFocus || initialFocusOnSSO}
          trackingProductId={trackingProductId}
        />
      </div>
      {shouldShowRecaptchaDisclaimer && (
        <Typography2 component="p" variant="bodySecondary" color="supportText" align="center" css={styles.supportText}>
          <FormattedMessage
            message={_t('This site is protected by reCAPTCHA and the {privacyPolicyLink} and {tosLink} apply.')}
            privacyPolicyLink={<Link href="https://policies.google.com/privacy">{_t('Google Privacy Policy')}</Link>}
            tosLink={<Link href="https://policies.google.com/terms">{_t('Terms of Service')}</Link>}
          />
        </Typography2>
      )}
    </div>
  );
};

export default EoiSSO;
