/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import useRouter from 'js/lib/useRouter';

import { Link, useTheme } from '@coursera/cds-core';

import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';

type Props = {
  href?: string;
  label?: Maybe<string>;
};

const HubBreadcrumb = ({ href, label }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const isCurrentPage = !href;

  return (
    <li
      key={`breadcrumb-${label}`}
      css={{
        display: 'inline',
      }}
    >
      <React.Fragment>
        <Link
          href={isCurrentPage ? router.location.pathname : href}
          variant="quiet"
          typographyVariant="bodyPrimary"
          style={{ color: 'var(--cds-color-grey-975)', fontWeight: isCurrentPage ? 500 : 700 }}
          aria-current={isCurrentPage ? 'page' : false}
        >
          {label}
        </Link>
        {!isCurrentPage && (
          <span css={{ margin: '0 var(--cds-spacing-100)' }} aria-hidden="true">
            &gt;
          </span>
        )}
      </React.Fragment>
    </li>
  );
};

export default HubBreadcrumb;
