/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import type { Theme } from '@coursera/cds-core';
import { Button, Checkbox, CheckboxGroup, FullscreenDialog, Radio, RadioGroup, breakpoints } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import type { DegreeFilterState } from 'bundles/premium-hub/components/degrees/useMultiSelectDegreeFilterStateManager';
import { getNameFromProductVariant } from 'bundles/premium-hub/utils';

import _t from 'i18n!nls/premium-hub';

type Props = {
  open: boolean;
  handleClose: () => void;
  degreeFilterState: DegreeFilterState;
};

const styles = {
  dialogContainer: css`
    hr {
      display: none;
    }
  `,
  dialogHeadingGroup: () => css`
    padding: var(--cds-spacing-100) var(--cds-spacing-200) var(--cds-spacing-300) var(--cds-spacing-200) !important;
  `,
  dialogContent: (theme: Theme) =>
    css({
      display: 'flex',
      flexFlow: 'column',
      gap: 'var(--cds-spacing-300)',
    }),
  actionContainer: (theme: Theme) =>
    css({
      display: 'flex',
      flexFlow: 'row wrap',
      gap: 'var(--cds-spacing-200)',
    }),
  actionButton: () => css`
    ${breakpoints.down('xs')} {
      width: 100%;
      max-width: none;
    }
  `,
};

const DegreeFiltersMobile = ({ open, handleClose, degreeFilterState }: Props) => {
  const trackComponent = useRetracked();
  const trackV3 = useTracker();
  const { Header, HeadingGroup, Content, Actions } = FullscreenDialog;
  const {
    setSelectedProductVariant,
    setSelectedDomain,
    setSelectedApplicationOpen,
    setSubmittedProductVariant,
    setSubmittedDomain,
    setSubmittedApplicationOpen,
    availableProductVariantFilters = [],
    availableDomainFilters = [],
    availableApplicationOpenFilters = [],
    selectedProductVariantValue = [],
    selectedDomainValue = [],
    selectedApplicationOpenValue,
    submittedDomain,
    submittedProductVariant,
    submittedApplicationOpen,
    clearAll,
  } = degreeFilterState;

  const handleApply = () => {
    const productVariantStr = selectedProductVariantValue.length === 0 ? '' : selectedProductVariantValue.join(',');
    const domainStr = selectedDomainValue.length === 0 ? '' : selectedDomainValue.join(',');
    trackComponent({
      trackingName: 'multiselect_filters_apply_mobile',
      trackingData: { submittedProductVariant: productVariantStr, submittedDomain: domainStr },
      action: 'click',
    });
    selectedProductVariantValue
      .filter((value) => !submittedProductVariant?.split(',').includes(value.toString()))
      .forEach((value) =>
        trackV3('perform_search', {
          filter: [`productVariant:${value}`],
        })
      );
    selectedDomainValue
      .filter((value) => !submittedDomain?.split(',').includes(value.toString()))
      .forEach((value) =>
        trackV3('perform_search', {
          filter: [`domain:${value}`],
        })
      );

    setSubmittedDomain(domainStr);
    setSubmittedProductVariant(productVariantStr);
    setSubmittedApplicationOpen(selectedApplicationOpenValue);
    handleClose();
  };

  const handleCloseDialog = () => {
    setSelectedDomain(submittedDomain);
    setSelectedProductVariant(submittedProductVariant);
    setSelectedApplicationOpen(submittedApplicationOpen);
    handleClose();
  };

  const handleChange = (selectedValue: string[], e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let selectedValueMobile = [...selectedValue];
    const value = e.target.value;
    if (checked) {
      if (!selectedValueMobile.includes(value)) {
        selectedValueMobile.push(value);
      }
    } else {
      selectedValueMobile = selectedValueMobile.filter((_) => _ !== value);
    }
    return selectedValueMobile.length === 0 ? '' : selectedValueMobile.join(',');
  };

  const showApplicationOpenFilter = false;

  const filterNums =
    selectedProductVariantValue.length + selectedDomainValue.length + (selectedApplicationOpenValue ? 1 : 0);
  const applyText = `${filterNums > 0 ? filterNums : ''} ${filterNums === 1 ? 'filter' : 'filters'}`;

  return (
    <FullscreenDialog
      open={open}
      onClose={handleCloseDialog}
      aria-describedby="degreeFiltersDialogContent"
      sticky="actions"
      css={styles.dialogContainer}
      data-testid="degree-filters-dialog"
    >
      <Header />
      <HeadingGroup heading={_t('All Filters')} css={styles.dialogHeadingGroup} />
      <Content id="degreeFiltersDialogContent" css={styles.dialogContent}>
        <CheckboxGroup
          label={_t('Program Level')}
          value={selectedProductVariantValue}
          onChange={(e, checked) => {
            const valueStr = handleChange(selectedProductVariantValue, e, checked);
            trackComponent({
              trackingName: 'multiselect_product_variant_mobile',
              trackingData: { submittedProductVariant: valueStr },
              action: 'change',
            });
            setSelectedProductVariant(valueStr);
          }}
        >
          {availableProductVariantFilters.map((productVariant) => (
            <Checkbox key={productVariant} label={getNameFromProductVariant(productVariant)} value={productVariant} />
          ))}
        </CheckboxGroup>
        <CheckboxGroup
          label={_t('Subject')}
          value={selectedDomainValue}
          onChange={(e, checked) => {
            const valueStr = handleChange(selectedDomainValue, e, checked);
            trackComponent({
              trackingName: 'multiselect_domain_mobile',
              trackingData: { submittedDomain: valueStr },
              action: 'change',
            });
            setSelectedDomain(valueStr);
          }}
        >
          {availableDomainFilters.map((domain) => (
            <Checkbox key={domain.id} label={domain.name} value={domain.id} />
          ))}
        </CheckboxGroup>

        {showApplicationOpenFilter && (
          <RadioGroup
            label={_t('Application Open')}
            value={selectedApplicationOpenValue || ''}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setSubmittedApplicationOpen(event?.target.value as string);
            }}
          >
            {availableApplicationOpenFilters.map(({ name, id }) => (
              <Radio key={id} value={id} label={name} />
            ))}
          </RadioGroup>
        )}
      </Content>

      <Actions css={styles.actionContainer}>
        <Button variant="primary" onClick={handleApply} css={styles.actionButton} disabled={!filterNums}>
          {_t('Apply #{applyText}', { applyText })}
        </Button>

        <Button variant="ghost" onClick={clearAll} css={styles.actionButton} disabled={!filterNums}>
          {_t('Clear all')}
        </Button>
      </Actions>
    </FullscreenDialog>
  );
};

export default DegreeFiltersMobile;
