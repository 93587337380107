/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography, breakpoints, typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import Markdown from 'bundles/cms/components/Markdown';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import Container from 'bundles/premium-hub/components/shared/Container';

import _t from 'i18n!nls/premium-hub';

const styles: Record<string, CSSProperties> = {
  spotlightWrapper: (theme: Theme) => ({
    marginTop: 'var(--cds-spacing-1000)',
    [`@media (max-width: ${breakpoints.values.md}px)`]: { marginTop: 'var(--cds-spacing-600)' },
    '.sectionTitle': {
      textAlign: 'center',
      marginBottom: 'var(--cds-spacing-800)',
    },
    '.profileRow': {
      display: 'flex',
      justifyContent: 'space-around',
      [`@media (max-width: ${breakpoints.values.md}px)`]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
      },
    },
  }),
  markdownWrapper: (theme: Theme) => ({
    '.rc-Markdown.styled': {
      h2: {
        ...typography2.subtitleMedium,
        color: 'var(--cds-color-grey-600)',
        marginBottom: 'var(--cds-spacing-300)',
      },
      h3: {
        ...theme.typography.h1semibold,
        marginTop: '0',
        marginBottom: 'var(--cds-spacing-300)',
      },
      p: typography2.bodyPrimary,
      [`@media (max-width: ${breakpoints.values.md}px)`]: { marginBottom: 'var(--cds-spacing-600)' },
    },
  }),

  profileImage: (theme: Theme) => ({
    width: '100%',
    height: '100%',
    display: 'block',
    marginBottom: 'var(--cds-spacing-400)',
  }),
};

type Props = {
  sectionTitle?: Maybe<string>;
  profileDescription?: Maybe<string>;
  profileImage?: Maybe<{ url?: Maybe<string> }>;
};

const UnifiedInstructorSpotlight = ({ sectionTitle, profileDescription, profileImage }: Props) => {
  const theme = useTheme();
  if (!sectionTitle) {
    return null;
  }

  return (
    <div css={styles.spotlightWrapper(theme)}>
      <Container data-test="rc-InstructorSpotlight">
        <Grid>
          <Grid className="sectionTitle">
            <Typography variant="h2">{sectionTitle}</Typography>
          </Grid>
        </Grid>
        <Grid className="profileRow">
          <Grid md={5}>
            <div css={styles.markdownWrapper(theme)}>
              <Markdown source={profileDescription} />
            </div>
          </Grid>
          <Grid md={5}>
            <div>
              <img
                src={(profileImage?.url as string) || (profileImage as string)}
                alt={_t('Instructor Profile')}
                css={styles.profileImage(theme)}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default UnifiedInstructorSpotlight;
