import * as React from 'react';

import * as Sentry from '@sentry/react';
import type { Location } from 'history';
import URI from 'jsuri';
// eslint-disable-next-line no-restricted-imports
import Q from 'q';

import API from 'js/lib/api';
import type { InjectedRouter, PatchedLocationDescriptor } from 'js/lib/connectToRouter';
import cookie from 'js/lib/cookie';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import type { NavigateFunction } from 'js/lib/useRouter';
import user from 'js/lib/user';

import { Link } from '@coursera/cds-core';

import { LEARNER_HELP_CENTER_LINK } from 'bundles/authentication/constants';
import localStorageEx from 'bundles/common/utils/localStorageEx';
import { getUrlParams, getUrlSource } from 'bundles/common/utils/urlUtils';
import DegreesConversionExperiments from 'bundles/epic/clients/DegreesConversion';
import DegreesDiscoveryExperiments from 'bundles/epic/clients/DegreesDiscovery';
import { ACTION_QUERY_PARAMS, ACTION_TYPES, EOI_ACTIONS } from 'bundles/expression-of-interest/constants';
import type {
  ActionQueryParam,
  ActionTypes,
  ContinueEoiParam,
  QualifyingQuestions,
} from 'bundles/expression-of-interest/types';
import type { PhoneDetails } from 'bundles/expression-of-interest/utils/PhoneUtils';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { getPhoneNumberInput } from 'bundles/expression-of-interest/utils/PhoneUtils';
import type { QuestionType } from 'bundles/survey-form/constants/SurveyFormConstants';
import { ResponseTypes } from 'bundles/survey-form/constants/SurveyFormConstants';
import { getResponseTypeFromQuestionType } from 'bundles/survey-form/utils/SurveyFormUtils';
import { LOCAL_STORAGE_EOI_EMBEDDED_SIGN_IN_DATA, ONE_HOUR_IN_MS } from 'bundles/xddp/constants';

import _t from 'i18n!nls/expression-of-interest';

const UTM_SOURCE = 'utm_source';
const UTM_MEDIUM = 'utm_medium';
const LEAD_ID = 'lead_id';

const LANDING_PAGE_ROUTE_NAME = 'landing';

// We set a lookback period of 6 months, which roughly aligns with 2 recruitment windows per year
// This way, learners only need to submit a new EOI every 6 months, and they will be able to access the application link right away next time
// In the future, we would want to replace this lookback with a backend store that actually tracks recruitment windows, and check if an EOI was submitted in the current window
export const EOI_SUBMISSION_LOOKBACK_PERIOD = 6;

export const reloadPage = () => {
  window.location.reload();
};

export const getActionQueryParam = (location: PatchedLocationDescriptor) => {
  const nextStep = location?.query?.nextStep;
  const action = location?.query?.action;

  switch (nextStep) {
    case ACTION_TYPES.emailMeInfo:
      return ACTION_QUERY_PARAMS.EMAIL_ME_INFO;
    case ACTION_TYPES.nextSteps:
      return ACTION_QUERY_PARAMS.NEXT_STEPS;
    default:
      return action;
  }
};

export const setActionQueryParamAndReload = (
  action: ActionQueryParam,
  location: Location,
  navigate: NavigateFunction,
  continueFrom?: ContinueEoiParam
) => {
  // Make sure other query params such as from=partner persist on query change
  const actionQuery = {
    ...location.query,
    action,
    ...(continueFrom ? { continueFrom } : {}),
  };
  const params = new URLSearchParams(actionQuery);

  navigate({
    pathname: location.pathname,
    search: `?${params.toString()}`,
  });

  reloadPage();
};

/** @deprecated Use setActionQueryParamAndReload instead */
export const setActionQueryParam = (action: ActionQueryParam, router: InjectedRouter) => {
  // Change query param to the action button that was clicked
  const {
    location: { query },
  } = router;

  // Make sure other query params such as from=partner persist on query change
  const actionQuery = {
    ...query,
    action,
  };
  router.push({
    pathname: window.location.pathname,
    query: actionQuery,
  });
};

/** Use this version. Rename to clearActionQueryParam once all usages of the deprecated version have been migrated */
export const clearActionQueryParamLocation = (location: Location, navigate: NavigateFunction) => {
  const query = location?.query;
  delete query.action;
  delete query.nextStep;
  delete query.continueFrom;

  const search = Object.keys(query).length > 0 ? '?' + new URLSearchParams({ ...query }).toString() : '';

  navigate({ pathname: location.pathname, search });
};

/** @deprecated Use clearActionQueryParamLocation instead */
export const clearActionQueryParam = (router: InjectedRouter) => {
  const {
    location: { query },
  } = router;

  delete query.action;
  delete query.nextStep;

  router.push({
    pathname: window.location.pathname,
    query,
  });
};

const QUERY_PARAMS_BY_ACTION = {
  [ACTION_TYPES.apply]: ACTION_QUERY_PARAMS.ENROLL,
  [ACTION_TYPES.emailMeInfo]: ACTION_QUERY_PARAMS.EMAIL_ME_INFO,
  [ACTION_TYPES.nextSteps]: ACTION_QUERY_PARAMS.NEXT_STEPS,
};

const ACTIONS_BY_QUERY_PARAM = {
  [ACTION_QUERY_PARAMS.ENROLL]: ACTION_TYPES.apply,
  [ACTION_QUERY_PARAMS.EMAIL_ME_INFO]: ACTION_TYPES.emailMeInfo,
  [ACTION_QUERY_PARAMS.NEXT_STEPS]: ACTION_TYPES.nextSteps,
};

export const queryParamFromActionType = (action: ActionTypes) => QUERY_PARAMS_BY_ACTION[action];

export const actionTypeFromActionQueryParam = (actionQueryParam: ActionQueryParam) =>
  ACTIONS_BY_QUERY_PARAM[actionQueryParam] || ACTION_TYPES.initial;

export const hasAction = (query: { action?: string }) => !!query.action;

export const getUnansweredQuestions = (questions: $TSFixMe, userInput: $TSFixMe) => {
  const HIDDEN_QUESTION_IDS = userInput.lastName ? ['firstName', 'lastName', 'emailAddress'] : [];

  return questions.filter((questionData: $TSFixMe) => {
    const isQuestionHidden = HIDDEN_QUESTION_IDS.includes(questionData.id);
    const isQuestionAnswered = userInput[questionData.id];
    const isHiddenQuestionAnswered = isQuestionHidden && isQuestionAnswered;

    return !isHiddenQuestionAnswered;
  });
};

export const isLandingPage = (routeName: string) => {
  return routeName === LANDING_PAGE_ROUTE_NAME;
};

export const getUserInfoQuestionPrefillKey = (questionType: string): string => {
  if (questionType === ResponseTypes.DROPDOWN_RESPONSE) {
    return 'id';
  }

  return 'text';
};

export const getUserInfoQuestions = (questions: $TSFixMe, userInput: $TSFixMe) => {
  try {
    let unansweredQuestions = questions;
    unansweredQuestions = getUnansweredQuestions(questions, userInput);

    const prefillQuestions = unansweredQuestions.map((questionData: $TSFixMe) => {
      const questionType = getResponseTypeFromQuestionType(Object.keys(questionData?.content)?.[0] as QuestionType);
      const isUserInputQuestionDefined = userInput?.[questionData?.id] && userInput?.[questionData?.id]?.[questionType];

      const prefillKey = getUserInfoQuestionPrefillKey(questionType);
      const prefillText = isUserInputQuestionDefined && {
        defaultValue: userInput?.[questionData?.id]?.[questionType]?.[prefillKey],
      };
      return {
        ...questionData,
        ...prefillText,
      };
    });
    return prefillQuestions;
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
};

export const updateUserProfile = (data: $TSFixMe) => {
  const api = API('', { type: 'rest' });
  return Q(api.put('/api/profiles.v1/' + data.userId, { data }));
};

export const getUserProfile = (
  data: $TSFixMe
): Q.Promise<{
  userProfile: {
    phone?: { phoneNumber: string; countryCode?: string; extension?: string };
    demographics?: {
      educationalAttainment?: number | null;
      degreeReadiness?: number | null;
      financialSituation?: number | null;
    };
  };
}> => {
  const profilesAPI = API('/api/profiles.v1/' + data.userId, { type: 'rest' });
  const uri = new URI().addQueryParam('fields', 'phone');

  return Q(profilesAPI.get(uri.toString(), { data: {} })).then((response) => {
    const userProfile = (response.elements && response.elements[0]) || {};

    return {
      userProfile,
    };
  });
};

type EoiContext = {
  source?: string;
  clickId?: string | null;
  utmParameters?: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
  };
};

/**
 * Searches for FB, Google or Impact Click Ids
 * @param {string} href - complete URL
 * @returns {string | null} - click id
 */
export const getClickId = (href: string): string | null => {
  try {
    const params = getUrlParams(href);
    return params?.gclid ?? params?.fbclid ?? params?.irclickid ?? null;
  } catch {
    Sentry.captureException('Failed to get click id from ref');
    return null;
  }
};

/**
 * Builds EOIContext from current complete URL
 * @param {string} href - full URL
 * @returns {EoiContext} EoiContext
 */
export const getEoiContext = (href: string): EoiContext => {
  try {
    const source = getUrlSource(href);
    const params = getUrlParams(href);
    const clickId = getClickId(href);

    const eoiContext = {
      source,
      utmParameters: {
        utmSource: params?.['utm-source'] ?? params?.utm_source,
        utmMedium: params?.['utm-medium'] ?? params?.utm_medium,
        utmCampaign: params?.['utm-campaign'] ?? params?.utm_campaign,
        utmTerm: params?.['utm-term'] ?? params?.utm_term,
        utmContent: params?.['utm-content'] ?? params?.utm_content,
      },
      ...(clickId != null ? { clickId } : {}),
    };

    return eoiContext;
  } catch (e) {
    Sentry.captureException(e);
    return {};
  }
};

export const isDestinationDegree = (slug: string): boolean => {
  return (
    DegreesDiscoveryExperiments.get('destinationDegreeList').find((degree: { id: string; slug: string }) => {
      return degree.slug === slug;
    }) !== undefined
  );
};

// copied from xddp
export const getSplitName = (fullName?: string) => {
  if (!fullName) {
    return {};
  }

  const trimmedFullName = fullName.trim();
  const splitName = trimmedFullName.split(' ');
  const firstName = splitName.shift();
  const lastName = splitName.length > 0 ? splitName.join(' ') : undefined;

  return {
    firstName,
    lastName,
  };
};

export const getResponse = (
  firstNameValue: string,
  lastNameValue: string,
  emailAddressValue: string,
  phoneNumberValue?: string,
  categoriesSelection?: { categoryId: string; categoryText: string }
) => {
  return {
    firstName: { 'org.coursera.survey.question.ShortAnswerResponse': { text: firstNameValue } },
    lastName: { 'org.coursera.survey.question.ShortAnswerResponse': { text: lastNameValue } },
    emailAddress: { 'org.coursera.survey.question.ShortAnswerResponse': { text: emailAddressValue } },
    phoneNumber: { 'org.coursera.survey.question.ShortAnswerResponse': { text: phoneNumberValue ?? '' } },
    ...(categoriesSelection && {
      categoriesSelection: {
        'org.coursera.survey.question.DropdownResponse': {
          id: categoriesSelection.categoryId,
          text: categoriesSelection.categoryText,
        },
      },
    }),
  };
};

export const isPerformancePathwayDegree = (slug: string): boolean => {
  return DegreesConversionExperiments.get('performancePathwayDegrees').includes(slug);
};

export const logFlowCompleteAction = (
  productId: string,
  logAction?: (action: string, metadata?: Record<string, string>) => void
): void => {
  try {
    const embeddedRegFormSignInData = localStorageEx.getItem(
      LOCAL_STORAGE_EOI_EMBEDDED_SIGN_IN_DATA,
      JSON.parse,
      undefined
    );
    if (embeddedRegFormSignInData) {
      const nowTs = Date.now();
      // If EOI submitted withing an hour of registration/sign-in using embedded reg form
      // Can be either one or two-step survey
      if (
        Number.isSafeInteger(embeddedRegFormSignInData?.ts) &&
        embeddedRegFormSignInData?.ts + ONE_HOUR_IN_MS > nowTs &&
        productId === String(embeddedRegFormSignInData.productId)
      ) {
        logAction?.(EOI_ACTIONS.FLOW_COMPLETE_WITHIN_ONE_HOUR, {
          msPassed: String(nowTs - embeddedRegFormSignInData?.ts),
        });
      }
    }
    logAction?.(EOI_ACTIONS.POST_SUCCESS);
    // clean-up
    localStorageEx.removeItem(LOCAL_STORAGE_EOI_EMBEDDED_SIGN_IN_DATA);
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const appendUtmParametersToApplicationLink = (applyLink: string): string => {
  const url = new URL(applyLink);

  const lowerCaseKeyMap: Record<string, string> = {};
  url.searchParams.forEach((value, key) => {
    lowerCaseKeyMap[key.toLowerCase()] = value;
  });

  if (!lowerCaseKeyMap[UTM_SOURCE]) {
    url.searchParams.append(UTM_SOURCE, 'coursera');
  }

  if (!lowerCaseKeyMap[UTM_MEDIUM]) {
    url.searchParams.append(UTM_MEDIUM, 'web');
  }

  if (!lowerCaseKeyMap[LEAD_ID]) {
    const sessionCookie = cookie.get('__204u');
    if (sessionCookie) {
      url.searchParams.append('lead_id', sessionCookie);
    }
  }

  return url.href;
};

type PhoneNumberInput = {
  phoneNumber: {
    [x: string]: {
      text: PhoneDetails;
    };
  };
};
type QualifyingQuestionsInput = {
  qualifyingQuestions: QualifyingQuestions;
};
export const getInputfromUserProfile = async () => {
  const { userProfile } = await getUserProfile({
    userId: user.get().id,
  });
  const { phone, demographics } = userProfile;
  let phoneNumberInput = {} as PhoneNumberInput | {};
  let qualifyingQuestionsInput = {} as QualifyingQuestionsInput | {};

  if (phone) {
    phoneNumberInput = getPhoneNumberInput(phone) as PhoneNumberInput;
  }

  if (demographics) {
    const { educationalAttainment, degreeReadiness, financialSituation } = demographics;
    if (educationalAttainment || degreeReadiness || financialSituation) {
      qualifyingQuestionsInput = {
        qualifyingQuestions: {
          highestEducation: educationalAttainment,
          learnerTimeline: degreeReadiness,
          financePlan: financialSituation,
        },
      } as QualifyingQuestionsInput;
    }
  }

  return {
    ...phoneNumberInput,
    ...qualifyingQuestionsInput,
  };
};

export const getDefaultError = () => {
  return (
    <FormattedMessage
      message={_t('An unexpected error has occurred. Please try again or contact our {helpCenterLink}.')}
      helpCenterLink={<Link href={LEARNER_HELP_CENTER_LINK}>{_t('Learner Help Center')}</Link>}
    />
  );
};
