import { useMemo } from 'react';

import type { SearchFilterOption } from 'bundles/search-common/types';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';
import { getFilterItems } from 'bundles/search-common/utils/getFilterItemsTranslations';

export const SEARCH_FILTER_LEARNING_PRODUCT_ORDER = [
  'Short Form Content Video',
  'Short Form Content Lesson',
  'Guided Projects',
  'Courses',
  'Projects',
  'Rhyme Projects',
  'Specializations',
  'Certificates',
  'Professional Certificates',
  'MasterTrack®',
  'MasterTrack® Certificates',
  'Degrees',
  'Postgraduate Diploma',
  'Graduate Certificates',
  'University Certificates',
];

const SEARCH_FILTER_LEARNING_PRODUCT_ORDER_EXPERIMENTAL: string[] = [
  'Courses',
  'Professional Certificates',
  'Specializations',
  'Projects',
  'Degrees',
  'Postgraduate and graduate diplomas',
];

export const REIMAGINE_LEVEL_ORDER = ['Beginner', 'Intermediate', 'Advanced', 'Mixed'];

const REIMAGINE_DURATION_ORDER = [
  'Under 60 Minutes',
  'Less Than 2 Hours',
  '1-4 Weeks',
  '1-3 Months',
  '3+ Months',
  '3-6 Months',
  '6-12 Months',
  '1-4 Years',
];

export const getSearchFilterOptionOrderOverrides = (attribute: string) => {
  switch (attribute) {
    case 'productDifficultyLevel':
      return REIMAGINE_LEVEL_ORDER;
    case 'productDurationEnum':
      return REIMAGINE_DURATION_ORDER;
    case 'productDuration':
      return REIMAGINE_DURATION_ORDER;
    case 'entityTypeDescription':
      return SEARCH_FILTER_LEARNING_PRODUCT_ORDER;
    case 'productTypeDescription':
      return isInFilterTestVariant()
        ? SEARCH_FILTER_LEARNING_PRODUCT_ORDER_EXPERIMENTAL
        : SEARCH_FILTER_LEARNING_PRODUCT_ORDER;
    default:
      return undefined;
  }
};

export type ItemType = SearchFilterOption & {
  supportText?: string;
};

export type SortConfig = {
  sortByCount?: boolean;
};

export const useSortedItems = (items: SearchFilterOption[], attribute: string, config?: SortConfig) => {
  const { sortByCount } = config || {};
  // Hardcode removing the .Properties filter name from the list of filters
  const removeFilterItems = useMemo(() => items.filter((item) => item.label !== '.Properties'), [items]);
  const filterItems: ItemType[] = useMemo(
    () => getFilterItems(removeFilterItems, attribute),
    [removeFilterItems, attribute]
  );

  // use specific ordering for Level, Duration, and entityType
  const optionItemOrderOverride = getSearchFilterOptionOrderOverrides(attribute);

  const sortedItems = [...filterItems];

  if (optionItemOrderOverride) {
    sortedItems.sort((item1, item2) => {
      if (item1.isRefined && !item2.isRefined) {
        return -1;
      } else if (!item1.isRefined && item2.isRefined) {
        return 1;
      } else if (optionItemOrderOverride.indexOf(item1.value) < optionItemOrderOverride.indexOf(item2.value)) {
        return -1;
      } else {
        return 1;
      }
    });
  } else {
    sortedItems.sort((item1, item2) => {
      if (item1.isRefined && !item2.isRefined) {
        return -1;
      } else if (!item1.isRefined && item2.isRefined) {
        return 1;
      } else if (sortByCount) {
        return item2.count - item1.count;
      } else {
        return item1.label.toLowerCase().localeCompare(item2.label.toLowerCase());
      }
    });
  }

  return sortedItems;
};

export default useSortedItems;
