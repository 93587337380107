import countryList from 'js/json/countries';

import getSkillTranslations from 'bundles/search-common/utils/getSkillTranslations';

import _t from 'i18n!nls/search-common';

type SearchFilterOptionItemTranslations = Record<
  string,
  Record<string, { title: string; supportText?: string; dialogText?: string } | undefined | string> | undefined
>;

// @deprecated use valueDisplay field instead of title field for translated labels
const getSearchFilterLearningProductTranslations = () => ({
  Courses: {
    title: _t('Courses'),
    supportText: _t('Learn from top instructors with graded assignments, videos, and discussion forums.'),
  },
  Projects: {
    title: _t('Projects'),
    supportText: _t('Learn a new tool or skill in an interactive, hands-on environment.'),
  },
  Specializations: {
    title: _t('Specializations'),
    supportText: _t('Get in-depth knowledge of a subject by completing a series of courses and projects.'),
  },
  'Guided Projects': {
    title: _t('Guided Projects'),
    supportText: _t('Build job-relevant skills in under 2 hours with hands-on tutorials.'),
  },
  Certificates: {
    title: _t('Professional Certificates'),
    supportText: _t('Earn career credentials from industry leaders that demonstrate your expertise.'),
  },
  'Professional Certificates': {
    title: _t('Professional Certificates'),
    supportText: _t('Earn career credentials from industry leaders that demonstrate your expertise.'),
  },
  Degrees: {
    title: _t('Degrees'),
    supportText: _t('Earn your Bachelor’s or Master’s degree online for a fraction of the cost of in-person learning.'),
  },
  'MasterTrack®': {
    title: _t('MasterTrack® Certificates'),
    supportText: _t('Earn career credentials while taking courses that count towards your Master’s degree.'),
  },
  'MasterTrack® Certificates': {
    title: _t('MasterTrack® Certificates'),
    supportText: _t('Earn career credentials while taking courses that count towards your Master’s degree.'),
  },
  'Postgraduate Diploma': {
    title: _t('Postgraduate Diploma'),
    supportText: _t('Complete graduate-level learning without committing to a full degree program.'),
  },
  'Graduate Certificates': {
    title: _t('Graduate Certificates'),
    supportText: _t('Earn a university-issued career credential in a flexible, interactive format.'),
  },
  'University Certificates': {
    title: _t('University Certificates'),
    supportText: _t('Graduate level learning within reach.'),
  },
  'Short Form Content Video': {
    title: _t('Videos'),
    supportText: _t('Explore subjects by watching short videos for quick learning'),
  },
  'Short Form Content Lesson': {
    title: _t('Lessons'),
    supportText: _t('Learn from a collection of videos addressing a well-defined learning objective'),
  },
});

// @deprecated use valueDisplay field instead for translated labels
const getSearchFilterLanguageTranslations = () => ({
  English: _t('English'),
  Spanish: _t('Spanish'),
  Russian: _t('Russian'),
  French: _t('French'),
  Arabic: _t('Arabic'),
  'Chinese (China)': _t('Chinese (China)'),
  Vietnamese: _t('Vietnamese'),
  Korean: _t('Korean'),
  German: _t('German'),
  'Portuguese (Brazil)': _t('Portuguese (Brazil)'),
  Turkish: _t('Turkish'),
  Italian: _t('Italian'),
  'Portuguese (Portugal)': _t('Portuguese (Portugal)'),
  Japanese: _t('Japanese'),
  Portuguese: _t('Portuguese'),
  'Chinese (Traditional)': _t('Chinese (Traditional)'),
  Romanian: _t('Romanian'),
  Afrikaans: _t('Afrikaans'),
  Polish: _t('Polish'),
  Tamil: _t('Tamil'),
  Mongolian: _t('Mongolian'),
  Czech: _t('Czech'),
  Chinese: _t('Chinese'),
  Bengali: _t('Bengali'),
  Bulgarian: _t('Bulgarian'),
  Estonian: _t('Estonian'),
  Albanian: _t('Albanian'),
  Slovak: _t('Slovak'),
  Telugu: _t('Telugu'),
  Croatian: _t('Croatian'),
  Nepali: _t('Nepali'),
  Georgian: _t('Georgian'),
  Lithuanian: _t('Lithuanian'),
  Kazakh: _t('Kazakh'),
  Indonesian: _t('Indonesian'),
  Thai: _t('Thai'),
  Burmese: _t('Burmese'),
  Catalan: _t('Catalan'),
  Dutch: _t('Dutch'),
  Greek: _t('Greek'),
  Hindi: _t('Hindi'),
  Persian: _t('Persian'),
  Swedish: _t('Swedish'),
  Uzbek: _t('Uzbek'),
  Ukrainian: _t('Ukrainian'),
  Marathi: _t('Marathi'),
  Danish: _t('Danish'),
  Hungarian: _t('Hungarian'),
  Javanese: _t('Javanese'),
  Serbian: _t('Serbian'),
  'Spanish (Latin America)': _t('Spanish (Latin America)'),
  Swahili: _t('Swahili'),
  Tagalog: _t('Tagalog'),
  Urdu: _t('Urdu'),
  Hebrew: _t('Hebrew'),
});

// @deprecated use valueDisplay field instead for translated labels
const getSearchFilterLevelTranslations = () => ({
  Beginner: _t('Beginner'),
  Intermediate: _t('Intermediate'),
  Mixed: _t('Mixed'),
  Advanced: _t('Advanced'),
});

// @deprecated use valueDisplay field instead for translated labels
const getSearchFilterDurationTranslations = () => ({
  '1-3 Months': _t('1-3 Months'),
  '1-4 Weeks': _t('1-4 Weeks'),
  'Less Than 2 Hours': _t('Less Than 2 Hours'),
  '3+ Months': _t('3+ Months'),
  'Under 60 Minutes': _t('Under 60 Minutes'),
  '3-6 Months': _t('3-6 Months'),
  '6-12 Months': _t('6-12 Months'),
  '1-4 Years': _t('1-4 Years'),
});

// If you edit these translations, update the labels in FILTER_INDEX_TO_FILTER_DATA.launchTs
// @deprecated use valueDisplay field instead for translated labels
const getLaunchTsTranslations = () => ({
  all: _t('All'),
  'last 2 weeks': _t('Last 2 weeks'),
  'last 30 days': _t('Last 30 days'),
  'last 60 days': _t('Last 60 days'),
  'last 6 months': _t('Last 6 months'),
  'last 1 year': _t('Last 1 year'),
});

// @deprecated use valueDisplay field instead for translated labels
export const getDegreeLevelTranslations = () => ({
  master: _t("Master's"),
  bachelor: _t("Bachelor's"),
  postgraduate: _t('Postgraduate Diploma'),
});

export const getCountryCodeTranslations = () => {
  const countryCodes: Record<string, string> = {};
  countryList.forEach((country) => {
    countryCodes[country.code] = country.name;
  });
  return countryCodes;
};

// @deprecated use valueDisplay field instead for translated labels
export const getTopicTranslations = () => ({
  'Data Science': _t('Data Science'),
  'Computer Science': _t('Computer Science'),
  Business: _t('Business'),
  'Information Technology': _t('Information Technology'),
  Health: _t('Health'),
  'Physical Science and Engineering': _t('Physical Science and Engineering'),
  'Social Sciences': _t('Social Sciences'),
  'Arts and Humanities': _t('Arts and Humanities'),
  'Personal Development': _t('Personal Development'),
  'Math and Logic': _t('Math and Logic'),
  'Language Learning': _t('Language Learning'),
});

// @deprecated use valueDisplay field instead of title field for translated labels
const getDegreePathwayTypeTranslations = () => ({
  None: _t('None'),
  'Progress Transfer': {
    title: _t('Recognizes prior coursework'),
    dialogText: _t(
      'Coursework can be applied as completed learning toward an eligible degree program upon admission, depending on institutional policies.'
    ),
  },
  'Prior Learning Credit': {
    title: _t('Recognizes prior learning credit'),
    dialogText: _t(
      'Completed Professional Certificates and Specializations can count as credit toward an eligible degree program upon admission, depending on institutional policies.'
    ),
  },
});

// @deprecated use valueDisplay field instead for translated labels
export const getDegreeMonthsTillNextAdmissionTranslations = () => ({
  'Next 3 months': _t('Next 3 months'),
  '3-6 months': _t('3-6 months'),
  '6-12 months': _t('6-12 months'),
});

export const getDegreeHasScholarshipTranslations = () => ({
  true: _t('Yes'),
  false: _t('No'),
});

// @deprecated use valueDisplay field instead
export const getSearchFilterOptionItemTranslations = (): SearchFilterOptionItemTranslations => ({
  entityTypeDescription: getSearchFilterLearningProductTranslations(),
  productTypeDescription: getSearchFilterLearningProductTranslations(),
  allLanguages: getSearchFilterLanguageTranslations(),
  language: getSearchFilterLanguageTranslations(),
  subtitleLanguage: getSearchFilterLanguageTranslations(),
  productDifficultyLevel: getSearchFilterLevelTranslations(),
  productDurationEnum: getSearchFilterDurationTranslations(),
  productDuration: getSearchFilterDurationTranslations(),
  launchTs: getLaunchTsTranslations(),
  degreeLevel: getDegreeLevelTranslations(),
  partnerCountry: getCountryCodeTranslations(),
  topic: getTopicTranslations(),
  skills: getSkillTranslations(),
  degreePathwayType: getDegreePathwayTypeTranslations(),
  degreeMonthsTillNextAdmission: getDegreeMonthsTillNextAdmissionTranslations(),
  degreeHasScholarship: getDegreeHasScholarshipTranslations(),
  // no need to translate partner name
});

export const getFilterItemsTranslations = <Item extends { label: string }>(
  items: Item[],
  attribute: string
): Item[] => {
  const optionItemTranslations = getSearchFilterOptionItemTranslations()[attribute];

  if (!optionItemTranslations) {
    return items;
  }

  const translatedItems = items.map((item: Item) => {
    const { label } = item;
    const translatedOption = optionItemTranslations[label];

    let translatedLabel = '';
    let translatedSupportText;
    let translatedDialogText;
    if (typeof translatedOption === 'object' && translatedOption !== null) {
      translatedLabel = translatedOption.title;
      translatedSupportText = translatedOption.supportText;
      translatedDialogText = translatedOption.dialogText;
    } else if (typeof translatedOption === 'string') {
      translatedLabel = translatedOption;
    } else {
      translatedLabel = label;
    }

    return {
      ...item,
      translatedLabel,
      supportText: translatedSupportText,
      dialogText: translatedDialogText,
    };
  });

  return translatedItems;
};

// A copy of the above function that doesn't inject the translated _t label
// because search is using translation proxy for translating the labels.
// We still need to inject supportText and dialogText and it's translations
export const getFilterItems = <Item extends { label: string; value: string }>(
  items: Item[],
  attribute: string
): Item[] => {
  const optionItemTranslations = getSearchFilterOptionItemTranslations()[attribute];

  if (!optionItemTranslations) {
    if (attribute === 'partners') {
      // prevent partners from being translated
      return items.map((item) => ({ ...item, label: item.value }));
    }
    return items;
  }

  const mappedItems = items.map((item: Item) => {
    const { value } = item;
    const translatedOption = optionItemTranslations[value];
    if (typeof translatedOption === 'object' && translatedOption !== null) {
      return {
        ...item,
        supportText: translatedOption.supportText,
        dialogText: translatedOption.dialogText,
      };
    } else {
      return item;
    }
  });

  return mappedItems;
};

export default getFilterItemsTranslations;
