/* eslint-disable react/no-danger */
import * as React from 'react';

import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import config from 'js/app/config';

import { DISPLAYED_DEGREES_COUNT, HubPages } from 'bundles/premium-hub/constants';
import type { Degree, PremiumProductProps } from 'bundles/premium-hub/types';
import { clearEmptyFields } from 'bundles/seo/utils/utils';

type Props = {
  allDegreeLists?: Array<{
    degreeType: string;
    title: string;
    degrees: Array<PremiumProductProps | Degree>;
  }>;
  currentHubPage: (typeof HubPages)[keyof typeof HubPages];
};

class DegreesCategorySchemaMarkup extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object,
  };

  declare context: LegacyContextType<typeof DegreesCategorySchemaMarkup.contextTypes>;

  createCanonicalDegreeLink(slug: $TSFixMe) {
    return `${config.url.base}degrees/${slug}`;
  }

  createItemList = (allDegreeLists: $TSFixMe, currentHubPage: $TSFixMe) => {
    let positionIndex = 0;

    return allDegreeLists.map((degreeList: $TSFixMe) => {
      const topDegrees =
        currentHubPage === HubPages.ProductVariantHubPage
          ? degreeList.degrees.slice(0, DISPLAYED_DEGREES_COUNT)
          : degreeList.degrees;

      return topDegrees.map((degree: $TSFixMe) => {
        positionIndex += 1;

        return {
          '@type': 'ListItem',
          position: positionIndex,
          url: this.createCanonicalDegreeLink(degree.slug),
        };
      });
    });
  };

  render() {
    const { allDegreeLists, currentHubPage } = this.props;

    if (!allDegreeLists || !allDegreeLists.length) {
      return null;
    }
    const itemListElement = [].concat(...this.createItemList(allDegreeLists, currentHubPage));

    const markupJson = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement,
    };

    const createMarkup = () => ({
      __html: `
        <script type="application/ld+json">
          ${JSON.stringify(clearEmptyFields(markupJson))}
        </script>
      `,
    });

    return (
      <div className="rc-DegreesCategorySchemaMarkup">
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
    );
  }
}

export default DegreesCategorySchemaMarkup;
