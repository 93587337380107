/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useState } from 'react';
import type { FocusEvent } from 'react';

import { Link } from '@coursera/cds-core';

import _t from 'i18n!nls/search-common';

const styles = {
  skipToResultsContainer: css`
    position: relative;
  `,
  skipToResults: css`
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1000;
    color: #fff !important;
    padding: 11px 20px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    background: #00419e;
    white-space: nowrap;

    &:focus,
    &:active {
      outline: 1px solid white;
    }

    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
  `,
};

type Props = {
  isMobile?: boolean;
};

const SkipToResultsLink = ({ isMobile }: Props) => {
  const [showSkipToResults, setShowSkipToResults] = useState(false);

  const handleSkipToMainContent = (event: FocusEvent<HTMLElement>) => {
    const { type } = event;
    if (type === 'focus') {
      setShowSkipToResults(true);
    } else if (type === 'blur') {
      setShowSkipToResults(false);
    }
  };

  return (
    !isMobile && (
      <div className={showSkipToResults ? '' : 'sr-only'} css={styles.skipToResultsContainer}>
        <Link
          css={styles.skipToResults}
          href="#searchResults"
          onFocus={handleSkipToMainContent}
          onBlur={handleSkipToMainContent}
        >
          {_t('Skip to search results')}
        </Link>
      </div>
    )
  );
};
export default SkipToResultsLink;
