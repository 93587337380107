/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useEffect, useMemo, useState } from 'react';

import { Hidden, Typography } from '@coursera/cds-core';

import { useEnterpriseAdminSearchContext } from 'bundles/enterprise-admin-product-search/context';
import SearchFilterGroup from 'bundles/search-common/components/filters/SearchFilterGroup';
import SearchFilterPlaceholder from 'bundles/search-common/components/filters/SearchFilterPlaceholder';
import SearchFiltersMobile from 'bundles/search-common/components/filters/SearchFiltersMobile';
import SkipToResultsLink from 'bundles/search-common/components/filters/SkipToResultsLink';
import getFiltersList from 'bundles/search-common/components/filters/getFiltersList';
import { ENTERPRISE_COLLECTION_ENTITIES } from 'bundles/search-common/constants';
import { useShowCourseraTier } from 'bundles/search-common/providers/LearnerProvider';
import type { SearchFilterEventingData, SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

type Props = {
  showingNoResults?: boolean;
  filters?: Record<string, SearchFilterOption[] | undefined>;
  isMobile?: boolean;
  activeFilters?: string[];
  eventingData: SearchFilterEventingData;
  filtersListOrder?: string[];
  addFilters: (filters: string) => void;
  removeFilters: (filters: string) => void;
  clearFilters: () => void;
  setFiltersByFacet?: (facetName: string, filters: string[]) => void;
  shouldHideSkillsFilter?: boolean;
  shouldHideSectionTitle?: boolean;
};

const styles = {
  placeholderContainer: css`
    padding-top: 30px;
  `,
  filterTitle: css`
    margin-bottom: 32px;
  `,
};

export const SearchFilters = ({
  showingNoResults,
  filters,
  isMobile,
  eventingData,
  activeFilters,
  filtersListOrder,
  addFilters,
  removeFilters,
  clearFilters,
  setFiltersByFacet,
  shouldHideSkillsFilter,
  shouldHideSectionTitle,
}: Props) => {
  const isEnterpriseAdminSearch = useEnterpriseAdminSearchContext();
  const shouldShowCourseraPlusFilter = useShowCourseraTier();

  // saving previous filters to prevent from showing placeholder and close modal
  const [previousFilters, setPreviousFilters] = useState(filters);
  useEffect(() => {
    if (filters && Object.keys(filters).length) {
      setPreviousFilters(filters);
    }
  }, [filters]);
  const currentFilters = filters && Object.keys(filters).length ? filters : previousFilters;

  const filtersList = useMemo(
    () =>
      getFiltersList({
        shouldShowCourseraPlusFilter,
        filtersListOrder,
        shouldHideSkillsFilter,
      }),
    [shouldShowCourseraPlusFilter, shouldHideSkillsFilter, filtersListOrder]
  );

  // Filters are loading if 'filters' (from Algolia) is undefined or is an empty object
  // and filtersList (static list of filters) is defined (which means we are expecting filter data from Algolia).
  const filtersLoading = (!currentFilters || !Object.keys(currentFilters).length) && !!filtersList;

  if (showingNoResults) {
    return null;
  }

  if (isMobile) {
    return (
      <SearchFiltersMobile
        filters={currentFilters}
        filtersList={filtersList}
        eventingData={eventingData}
        activeFilters={activeFilters}
        filtersLoading={filtersLoading}
        addFilters={addFilters}
        removeFilters={removeFilters}
        clearFilters={clearFilters}
      />
    );
  }

  if (filtersLoading) {
    return (
      <div css={styles.placeholderContainer}>
        {Array.from(Array(7)).map((_item, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <SearchFilterPlaceholder key={index} isCompact={true} />;
        })}
      </div>
    );
  } else {
    return (
      <Hidden smDown>
        <SkipToResultsLink isMobile={isMobile} />
        {!shouldHideSectionTitle && (
          <Typography variant="h2semibold" component="h2" css={styles.filterTitle}>
            {_t('Filter by')}
          </Typography>
        )}
        {filtersList.map((filter) => {
          const attribute = filter.property;
          let items = currentFilters?.[attribute];

          if (isEnterpriseAdminSearch && items && attribute === 'productTypeDescription') {
            items = items?.filter((_) => ENTERPRISE_COLLECTION_ENTITIES.includes(_.label));
          }
          return (
            <SearchFilterGroup
              key={attribute}
              attribute={attribute}
              addFilters={addFilters}
              setFiltersByFacet={setFiltersByFacet}
              removeFilters={removeFilters}
              clearFilters={clearFilters}
              eventingData={{
                searchIndex: eventingData.searchIndex,
                searchIndexPosition: eventingData.searchIndexPosition,
                activeFilterItems: eventingData.filtersApplied,
              }}
              {...filter}
              items={items}
            />
          );
        })}
      </Hidden>
    );
  }
};

export default SearchFilters;
