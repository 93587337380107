import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('multiSelectOption', [
  'root',
  'icon',
  'focusVisible',
  'button',
  'selected',
  'container',
  'content',
  'label',
  'labelRow',
  'suffix',
  'supportText',
  'checkbox',
  'disabled',
  'checkboxIcon',
  'singleSelect',
  'selectedIcon',
  'hasSelectedItem',
]);

const styles = css`
  cursor: pointer;
  padding: var(--cds-spacing-50) 0;
  border-radius: var(--cds-border-radius-50);
  outline: 0;

  .${classes.checkboxIcon} {
    fill: var(--cds-color-neutral-background-primary);
    color: var(--cds-color-neutral-stroke-primary);
  }

  .${classes.container} {
    display: flex;
    padding: var(--cds-spacing-100);
    flex: 1 0 100%;
    max-width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: calc(var(--cds-border-radius-50) - 1px);
    box-sizing: border-box;
    color: var(--cds-color-neutral-primary);
  }

  .${classes.content} {
    flex: 1;
    margin-left: var(--cds-spacing-150);
  }

  .${classes.labelRow} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .${classes.label} {
    flex: 1;
  }

  .${classes.suffix} {
    margin-left: var(--cds-spacing-100);
  }

  .${classes.supportText} {
    margin-top: var(--cds-spacing-50);
  }

  &.${classes.selected} {
    background: var(--cds-color-neutral-background-primary);

    .${classes.checkboxIcon} {
      color: var(--cds-color-interactive-primary);
      fill: var(--cds-color-interactive-primary);
    }
  }

  &.${classes.focusVisible} {
    position: relative;
    z-index: 1;
    background: none;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 0;
      left: 0;
      border-radius: var(--cds-spacing-50);
      box-shadow: 0 0 0 1px var(--cds-color-interactive-stroke-primary-focus),
        0 0 0 2px var(--cds-color-interactive-stroke-primary-focus-invert);
      outline: 1px solid transparent;
      outline-offset: 2px;
    }
  }

  &.${classes.button} {
    transition: none;
  }

  &.${classes.root} {
    &:hover {
      background: var(--cds-color-interactive-background-primary-hover-weak);

      & .${classes.container} {
        background: var(--cds-color-interactive-background-primary-hover-weak);
      }
    }
  }

  /*
    Single select option
  */

  &.${classes.singleSelect} {
    .${classes.content} {
      margin-left: 0;
    }

    &:hover,
    &.${classes.selected}, &.${classes.focusVisible} {
      background: var(--cds-color-interactive-background-primary-hover-weak);

      .${classes.labelRow} {
        color: var(--cds-color-interactive-primary-hover);
      }
    }

    .${classes.selectedIcon} {
      color: var(--cds-color-interactive-primary-hover);
      fill: var(--cds-color-interactive-primary-hover);
      margin-top: 2px;
      margin-left: var(--cds-spacing-150);

      &.${classes.hasSelectedItem} {
        /* If any items are selected, add the space to each option to ensure suffixes align */
        min-width: 24px;
      }

      & > svg {
        display: block;
      }
    }
  }
`;

export default styles;
