import { useQuery } from '@apollo/client';
import type { DegreeDescriptionPageModules_ProgramAdmissionDeadlines as ProgramAdmissionDeadlines } from '__generated__/graphql-types-do-not-use';
import moment from 'moment';

import { MOMENT_FORMATS, formatDateTimeDisplay } from 'js/utils/DateTimeUtils/DateTimeUtils';

import type {
  GetProgramAdmissionDeadlinesBySlugQuery,
  GetProgramAdmissionDeadlinesBySlugQueryVariables,
} from 'bundles/expression-of-interest/queries/__generated__/getProgramAdmissionDeadlinesBySlug';
import getCourseById from 'bundles/expression-of-interest/queries/getProgramAdmissionDeadlinesBySlug.graphql';

const validDegreeProgramAdmissionDeadline = (date?: string | null) => {
  const now = moment();

  return moment.utc(date).endOf('day') > now
    ? formatDateTimeDisplay(moment.utc(date).endOf('day'), MOMENT_FORMATS.LONG_DATE_ONLY_DISPLAY)
    : undefined;
};

const getNextStepsProgramAdmissionDeadline = (deadline?: ProgramAdmissionDeadlines) => {
  if (!deadline) return undefined;

  const priorityDeadline = validDegreeProgramAdmissionDeadline(deadline?.priorityDeadlineDate);
  const regularDeadline = validDegreeProgramAdmissionDeadline(deadline?.subtermDeadlineDate);
  const extendedDeadline = validDegreeProgramAdmissionDeadline(deadline?.extendedSubtermDeadlineDate);

  return priorityDeadline ?? regularDeadline ?? extendedDeadline ?? undefined;
};

const HOTFIX_DEGREE_HUB_SLUGS = [
  'degrees_hub',
  'bachelors',
  'masters',
  'postgraduate-studies',
  'business',
  'computer-science',
  'data-science',
  'public-health',
  'data-analytics',
  'top-european',
  'maestria-en-linea-latinoamerica',
  'top-indian',
  'learn',
  'mastertrack',
];

export const useDegreeProgramAdmissionDeadlines = (degreeSlug?: string, skip?: boolean) => {
  const isADegreeHubPage = HOTFIX_DEGREE_HUB_SLUGS.includes(degreeSlug ?? '');
  const shouldOverrideSkip = !degreeSlug || isADegreeHubPage;

  const { loading, data } = useQuery<
    GetProgramAdmissionDeadlinesBySlugQuery,
    GetProgramAdmissionDeadlinesBySlugQueryVariables
  >(getCourseById, {
    skip: shouldOverrideSkip || skip,
    variables: { degreeSlug: degreeSlug ?? '' },
    context: { clientName: 'gatewayGql' },
  });

  const deadlines = data?.DegreeDescriptionPageModules?.queryProgramAdmissionDeadlines;

  const firstDeadline = deadlines?.[0];

  const nextStepsDeadline = getNextStepsProgramAdmissionDeadline(firstDeadline)?.toString();

  return { loading, deadlines, nextStepsDeadline };
};
