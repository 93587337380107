import _t from 'i18n!nls/search-common';

// @deprecated use valueDisplay field instead
const getSkillTranslations = (): Record<string, string> => ({
  'Account Management': _t('Account Management'),
  Accounting: _t('Accounting'),
  'Accounting Software': _t('Accounting Software'),
  'Accounts Payable and Receivable': _t('Accounts Payable and Receivable'),
  Adaptability: _t('Adaptability'),
  Advertising: _t('Advertising'),
  'Advertising Sales': _t('Advertising Sales'),
  'Agile Software Development': _t('Agile Software Development'),
  Algebra: _t('Algebra'),
  Algorithms: _t('Algorithms'),
  'Amazon Web Services': _t('Amazon Web Services'),
  'Android Development': _t('Android Development'),
  Angular: _t('Angular'),
  Apache: _t('Apache'),
  'Application Development': _t('Application Development'),
  'Applied Machine Learning': _t('Applied Machine Learning'),
  'Applied Mathematics': _t('Applied Mathematics'),
  ArcGIS: _t('ArcGIS'),
  'Artificial Neural Networks': _t('Artificial Neural Networks'),
  Audit: _t('Audit'),
  'B2B Sales': _t('B2B Sales'),
  'Back-End Web Development': _t('Back-End Web Development'),
  Banking: _t('Banking'),
  'Basic Descriptive Statistics': _t('Basic Descriptive Statistics'),
  'Bayesian Network': _t('Bayesian Network'),
  'Bayesian Statistics': _t('Bayesian Statistics'),
  'Behavioral Economics': _t('Behavioral Economics'),
  Benefits: _t('Benefits'),
  'Big Data': _t('Big Data'),
  'Billing & Invoicing': _t('Billing & Invoicing'),
  Bioinformatics: _t('Bioinformatics'),
  Biostatistics: _t('Biostatistics'),
  BlockChain: _t('BlockChain'),
  'Brand Management': _t('Brand Management'),
  'Budget Management': _t('Budget Management'),
  Business: _t('Business'),
  'Business Analysis': _t('Business Analysis'),
  'Business Communication': _t('Business Communication'),
  'Business Design': _t('Business Design'),
  'Business Development': _t('Business Development'),
  'Business Intelligence': _t('Business Intelligence'),
  'Business Process Management': _t('Business Process Management'),
  'Business Psychology': _t('Business Psychology'),
  'Business Research': _t('Business Research'),
  'Business Transformation': _t('Business Transformation'),
  'C Programming Language Family': _t('C Programming Language Family'),
  'C++ Programming': _t('C++ Programming'),
  Calculus: _t('Calculus'),
  'Cash Management': _t('Cash Management'),
  'Change Management': _t('Change Management'),
  'Clinical Data Management': _t('Clinical Data Management'),
  'Cloud API': _t('Cloud API'),
  'Cloud Applications': _t('Cloud Applications'),
  'Cloud Clients': _t('Cloud Clients'),
  'Cloud Computing': _t('Cloud Computing'),
  'Cloud Engineering': _t('Cloud Engineering'),
  'Cloud Infrastructure': _t('Cloud Infrastructure'),
  'Cloud Load Balancing': _t('Cloud Load Balancing'),
  'Cloud Management': _t('Cloud Management'),
  'Cloud Platforms': _t('Cloud Platforms'),
  'Cloud Standards': _t('Cloud Standards'),
  'Cloud Storage': _t('Cloud Storage'),
  'Cloud-Based Integration': _t('Cloud-Based Integration'),
  Collaboration: _t('Collaboration'),
  Combinatorics: _t('Combinatorics'),
  Communication: _t('Communication'),
  Compensation: _t('Compensation'),
  'Computational Logic': _t('Computational Logic'),
  'Computational Thinking': _t('Computational Thinking'),
  'Computer Architecture': _t('Computer Architecture'),
  'Computer Graphic Techniques': _t('Computer Graphic Techniques'),
  'Computer Graphics': _t('Computer Graphics'),
  'Computer Networking': _t('Computer Networking'),
  'Computer Programming': _t('Computer Programming'),
  'Computer Programming Tools': _t('Computer Programming Tools'),
  'Computer Science': _t('Computer Science'),
  'Computer Security Incident Management': _t('Computer Security Incident Management'),
  'Computer Security Models': _t('Computer Security Models'),
  'Computer Vision': _t('Computer Vision'),
  'Conflict Management': _t('Conflict Management'),
  'Continuous Delivery': _t('Continuous Delivery'),
  'Continuous Integration': _t('Continuous Integration'),
  'Contract Management': _t('Contract Management'),
  'Corporate Accouting': _t('Corporate Accounting'),
  'Correlation And Dependence': _t('Correlation And Dependence'),
  'Cost Accounting': _t('Cost Accounting'),
  Creativity: _t('Creativity'),
  'Critical Thinking': _t('Critical Thinking'),
  'Cross Platform Development': _t('Cross Platform Development'),
  Cryptography: _t('Cryptography'),
  Culture: _t('Culture'),
  'Customer Analysis': _t('Customer Analysis'),
  'Customer Relationship Management': _t('Customer Relationship Management'),
  'Customer Success': _t('Customer Success'),
  'Customer Support': _t('Customer Support'),
  Cyberattacks: _t('Cyberattacks'),
  'Data Analysis': _t('Data Analysis'),
  'Data Analysis Software': _t('Data Analysis Software'),
  'Data Architecture': _t('Data Architecture'),
  'Data Engineering': _t('Data Engineering'),
  'Data Management': _t('Data Management'),
  'Data Mining': _t('Data Mining'),
  'Data Model': _t('Data Model'),
  'Data Science': _t('Data Science'),
  'Data Structures': _t('Data Structures'),
  'Data Visualization': _t('Data Visualization'),
  'Data Visualization Software': _t('Data Visualization Software'),
  'Data Warehousing': _t('Data Warehousing'),
  'Database Administration': _t('Database Administration'),
  'Database Application': _t('Database Application'),
  'Database Design': _t('Database Design'),
  'Database Theory': _t('Database Theory'),
  Databases: _t('Databases'),
  'Decision Making': _t('Decision Making'),
  'Deep Learning': _t('Deep Learning'),
  'Design and Product': _t('Design and Product'),
  DevOps: _t('DevOps'),
  'Devops Tools': _t('Devops Tools'),
  'Differential Equations': _t('Differential Equations'),
  'Digital Marketing': _t('Digital Marketing'),
  'Dimensionality Reduction': _t('Dimensionality Reduction'),
  'Distributed Computing Architecture': _t('Distributed Computing Architecture'),
  'Django (Web Framework)': _t('Django (Web Framework)'),
  'Docker (Software)': _t('Docker (Software)'),
  'E-Commerce': _t('E-Commerce'),
  Econometrics: _t('Econometrics'),
  'Emotional Intelligence': _t('Emotional Intelligence'),
  'Employee Relations': _t('Employee Relations'),
  'Entrepreneurial Finance': _t('Entrepreneurial Finance'),
  Entrepreneurship: _t('Entrepreneurship'),
  Epidemiology: _t('Epidemiology'),
  Estimation: _t('Estimation'),
  Experiment: _t('Experiment'),
  'Exploratory Data Analysis': _t('Exploratory Data Analysis'),
  'Extract, Transform, Load': _t('Extract, Transform, Load'),
  'Facility Management': _t('Facility Management'),
  'Feature Engineering': _t('Feature Engineering'),
  Finance: _t('Finance'),
  'Financial Accounting': _t('Financial Accounting'),
  'Financial Analysis': _t('Financial Analysis'),
  'Financial Management': _t('Financial Management'),
  FinTech: _t('FinTech'),
  Forecasting: _t('Forecasting'),
  'Front-End Web Development': _t('Front-End Web Development'),
  'Full-Stack Web Development': _t('Full-Stack Web Development'),
  'Game Theory': _t('Game Theory'),
  'General Accounting': _t('General Accounting'),
  'General Statistics': _t('General Statistics'),
  'Generally Accepted Accounting Principles (GAAP)': _t('Generally Accepted Accounting Principles (GAAP)'),
  Geometry: _t('Geometry'),
  Geostatistics: _t('Geostatistics'),
  Geovisualization: _t('Geovisualization'),
  'GIS Software': _t('GIS Software'),
  'Google App Engine': _t('Google App Engine'),
  'Google Cloud Platform': _t('Google Cloud Platform'),
  'Graph Theory': _t('Graph Theory'),
  'Graphic Design': _t('Graphic Design'),
  'Graphics Software': _t('Graphics Software'),
  'Hardware Design': _t('Hardware Design'),
  Health: _t('Health'),
  'HR Tech': _t('HR Tech'),
  'HTML and CSS': _t('HTML and CSS'),
  'Human Computer Interaction': _t('Human Computer Interaction'),
  'Human Factors (Security)': _t('Human Factors (Security)'),
  'Human Learning': _t('Human Learning'),
  'Human Resources': _t('Human Resources'),
  'Human Resources Operations': _t('Human Resources Operations'),
  'IBM Cloud': _t('IBM Cloud'),
  Influencing: _t('Influencing'),
  'Information Technology': _t('Information Technology'),
  Innovation: _t('Innovation'),
  'Inside Sales': _t('Inside Sales'),
  'Insurance Sales': _t('Insurance Sales'),
  'Interactive Data Visualization': _t('Interactive Data Visualization'),
  'Interactive Design': _t('Interactive Design'),
  'Internet Of Things': _t('Internet Of Things'),
  'Inventory Management': _t('Inventory Management'),
  'Investment Management': _t('Investment Management'),
  'iOS Development': _t('iOS Development'),
  'Java Programming': _t('Java Programming'),
  Javascript: _t('Javascript'),
  'Jira (Software)': _t('Jira (Software)'),
  Journalism: _t('Journalism'),
  Knitr: _t('Knitr'),
  Kubernetes: _t('Kubernetes'),
  'Leadership and Management': _t('Leadership and Management'),
  'Leadership Development': _t('Leadership Development'),
  'Linear Algebra': _t('Linear Algebra'),
  Linux: _t('Linux'),
  'Looker (Software)': _t('Looker (Software)'),
  'Machine Learning': _t('Machine Learning'),
  'Machine Learning Algorithms': _t('Machine Learning Algorithms'),
  'Machine Learning Software': _t('Machine Learning Software'),
  'Management Accounting': _t('Management Accounting'),
  'Market Analysis': _t('Market Analysis'),
  'Market Research': _t('Market Research'),
  Marketing: _t('Marketing'),
  'Marketing Design': _t('Marketing Design'),
  'Marketing Management': _t('Marketing Management'),
  'Marketing Psychology': _t('Marketing Psychology'),
  'Markov Model': _t('Markov Model'),
  'Material Handling': _t('Material Handling'),
  'Mathematical Theory & Analysis': _t('Mathematical Theory & Analysis'),
  Mathematics: _t('Mathematics'),
  Matlab: _t('Matlab'),
  'Media Production': _t('Media Production'),
  'Media Strategy & Planning': _t('Media Strategy & Planning'),
  Merchandising: _t('Merchandising'),
  'Mergers & Acquisitions': _t('Mergers & Acquisitions'),
  Microarchitecture: _t('Microarchitecture'),
  'Microsoft Azure': _t('Microsoft Azure'),
  'Microsoft Excel': _t('Microsoft Excel'),
  Minitab: _t('Minitab'),
  'Mobile Development': _t('Mobile Development'),
  'Mobile Development Tools': _t('Mobile Development Tools'),
  'Mobile Security': _t('Mobile Security'),
  'Natural Language Processing': _t('Natural Language Processing'),
  Negotiation: _t('Negotiation'),
  'Network Analysis': _t('Network Analysis'),
  'Network Architecture': _t('Network Architecture'),
  'Network Model': _t('Network Model'),
  'Network Security': _t('Network Security'),
  'Networking Hardware': _t('Networking Hardware'),
  NoSQL: _t('NoSQL'),
  'Operating Systems': _t('Operating Systems'),
  'Operational Analysis': _t('Operational Analysis'),
  'Operations Management': _t('Operations Management'),
  'Operations Research': _t('Operations Research'),
  'Organizational Development': _t('Organizational Development'),
  'Other Cloud Platforms and Tools': _t('Other Cloud Platforms and Tools'),
  'Other Mobile Programming Languages': _t('Other Mobile Programming Languages'),
  'Other Programming Languages': _t('Other Programming Languages'),
  'Other Web Frameworks': _t('Other Web Frameworks'),
  'Other Web Programming Languages': _t('Other Web Programming Languages'),
  'Outside Sales': _t('Outside Sales'),
  Payments: _t('Payments'),
  'People Analysis': _t('People Analysis'),
  'People Development': _t('People Development'),
  'People Management': _t('People Management'),
  'Performance Management': _t('Performance Management'),
  'Persona Research': _t('Persona Research'),
  'Personal Development': _t('Personal Development'),
  Planning: _t('Planning'),
  'Plot (Graphics)': _t('Plot (Graphics)'),
  PostgreSQL: _t('PostgreSQL'),
  'Power BI': _t('Power BI'),
  'Probability & Statistics': _t('Probability & Statistics'),
  'Probability Distribution': _t('Probability Distribution'),
  'Problem Solving': _t('Problem Solving'),
  'Process Analysis': _t('Process Analysis'),
  Procurement: _t('Procurement'),
  'Product Design': _t('Product Design'),
  'Product Development': _t('Product Development'),
  'Product Lifecycle': _t('Product Lifecycle'),
  'Product Management': _t('Product Management'),
  'Product Marketing': _t('Product Marketing'),
  'Product Strategy': _t('Product Strategy'),
  'Professional Development': _t('Professional Development'),
  'Programming Principles': _t('Programming Principles'),
  'Project Management': _t('Project Management'),
  'Prospecting and Qualification': _t('Prospecting and Qualification'),
  'Public Relations': _t('Public Relations'),
  'Python Programming': _t('Python Programming'),
  'R Programming': _t('R Programming'),
  'React (web framework)': _t('React (web framework)'),
  Recruitment: _t('Recruitment'),
  Regression: _t('Regression'),
  'Regulations and Compliance': _t('Regulations and Compliance'),
  'Reinforcement Learning': _t('Reinforcement Learning'),
  'Research and Design': _t('Research and Design'),
  Resilience: _t('Resilience'),
  'Retail Sales': _t('Retail Sales'),
  'Retail Store Operations': _t('Retail Store Operations'),
  'Risk Management': _t('Risk Management'),
  Sales: _t('Sales'),
  'Sales Systems': _t('Sales Systems'),
  Salesforce: _t('Salesforce'),
  'SAS (Software)': _t('SAS (Software)'),
  'Scala Programming': _t('Scala Programming'),
  'Scientific Visualization': _t('Scientific Visualization'),
  'Scrum (Software Development)': _t('Scrum (Software Development)'),
  'Search Engine Optimization': _t('Search Engine Optimization'),
  'Securities Sales': _t('Securities Sales'),
  'Securities Trading': _t('Securities Trading'),
  'Security Engineering': _t('Security Engineering'),
  'Security Software': _t('Security Software'),
  'Security Strategy': _t('Security Strategy'),
  'Semantic Web': _t('Semantic Web'),
  'Shipping and Receiving': _t('Shipping and Receiving'),
  'Small Data': _t('Small Data'),
  'Social Media': _t('Social Media'),
  'Software Architecture': _t('Software Architecture'),
  'Software As A Service': _t('Software As A Service'),
  'Software Engineering': _t('Software Engineering'),
  'Software Engineering Tools': _t('Software Engineering Tools'),
  'Software Framework': _t('Software Framework'),
  'Software Security': _t('Software Security'),
  'Software Testing': _t('Software Testing'),
  'Software Visualization': _t('Software Visualization'),
  'Software-Defined Networking': _t('Software-Defined Networking'),
  'Solution Sales Engineering': _t('Solution Sales Engineering'),
  'Spatial Analysis': _t('Spatial Analysis'),
  'Spatial Data Analysis': _t('Spatial Data Analysis'),
  'Spreadsheet Software': _t('Spreadsheet Software'),
  SPSS: _t('SPSS'),
  SQL: _t('SQL'),
  Stata: _t('Stata'),
  'Statistical Analysis': _t('Statistical Analysis'),
  'Statistical Machine Learning': _t('Statistical Machine Learning'),
  'Statistical Programming': _t('Statistical Programming'),
  'Statistical Tests': _t('Statistical Tests'),
  'Statistical Visualization': _t('Statistical Visualization'),
  'Store Management': _t('Store Management'),
  Storytelling: _t('Storytelling'),
  Strategy: _t('Strategy'),
  'Strategy and Operations': _t('Strategy and Operations'),
  'Supplier Relationship Management': _t('Supplier Relationship Management'),
  'Supply Chain and Logistics': _t('Supply Chain and Logistics'),
  'Supply Chain Systems': _t('Supply Chain Systems'),
  'Survey Creation': _t('Survey Creation'),
  'Swift Programming': _t('Swift Programming'),
  'System Programming': _t('System Programming'),
  'System Security': _t('System Security'),
  'System Software': _t('System Software'),
  'Systems Design': _t('Systems Design'),
  'Tableau Software': _t('Tableau Software'),
  'Talent Management': _t('Talent Management'),
  Taxes: _t('Taxes'),
  'Technical Product Management': _t('Technical Product Management'),
  Tensorflow: _t('Tensorflow'),
  'Theoretical Computer Science': _t('Theoretical Computer Science'),
  Training: _t('Training'),
  'Transportation Operations Management': _t('Transportation Operations Management'),
  'User Experience': _t('User Experience'),
  'User Experience Design': _t('User Experience Design'),
  'User Research': _t('User Research'),
  'Vendor Management': _t('Vendor Management'),
  'Virtual Reality': _t('Virtual Reality'),
  'Visual Design': _t('Visual Design'),
  'Visualization (Computer Graphics)': _t('Visualization (Computer Graphics)'),
  'Warehouse Management': _t('Warehouse Management'),
  'Web Design': _t('Web Design'),
  'Web Development': _t('Web Development'),
  'Web Development Tools': _t('Web Development Tools'),
  Writing: _t('Writing'),
});

export default getSkillTranslations;
