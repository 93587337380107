import React, { useEffect, useState, useRef } from 'react';

import { Collapse } from '@material-ui/core';

import clsx from 'clsx';

import { useLocalizedStringFormatter, useId } from '@coursera/cds-common';
import type { ForwardRefComponent } from '@coursera/cds-common';
import { ChevronNextIcon } from '@coursera/cds-icons';

import Button from '@core/Button';
import VisuallyHidden from '@core/VisuallyHidden';

import getShowMoreListCss, { classes } from './getShowMoreListCss';
import i18nMessages from './i18n';

type ShowMoreListType = ForwardRefComponent<HTMLDivElement, Props>;

export type Props = {
  /**
   * Array of items to render inside the list container. The container will map over these
   * and partially hide the list based on `numItemsToShow`.
   */
  children: React.ReactNode[];

  /**
   * The number of items to show when the list is collapsed. When expanded, all the items will be visible.
   * @default 1
   */
  numItemsToShow?: number;

  /**
   * Callback fired when the expanded state is changed.
   * @param {boolean} isExpanded new state after change
   */
  onChange?: (isExpanded: boolean) => void;

  /**
   * Props specific to the list container element
   *
   * @param accessibleTitle Optional title for the list container, use this to describe the list. By default, this will be "{children.length} {itemTypePlural}" e.g. "7 courses".
   */
  containerProps?: {
    accessibleTitle?: string;
  };

  /**
   * Props specific to the controller CTA element
   *
   * @param ariaLabel Override the default CTA label if required
   *
   * @param itemTypePlural The display name (in plural) for the type of the items being rendered in the list, used in the CTA label. For example, if the list renders courses, then this should be "courses", resulting in the final label as "Show all N courses". By default, this is "list items"
   *
   * @param expandedLabel Override the default expanded label if required
   *
   * @param collapsedLabel Override the default collapsed label if required
   */
  controllerProps?: {
    ariaLabel?: string;
    itemTypePlural?: string;
    expandedLabel?: string;
    collapsedLabel?: string;
  };

  /**
   * Base ID to be used for a11y, will be used as a prefix for subcomponents. By default, it will be autogenerated in CDS.
   * @ignore
   */
  id?: string;

  /**
   * Optional additional classes for the root component
   */
  className?: string;
};

/**
 * ShowMoreList partially hides a list of related elements to enable the user to navigate
 * through the core content efficiently. The component is ideal for expanding a
 * list of up to 12 items.
 *
 * See [Props](__storybookUrl__/components-surfaces-show-more-list--default#props)
 *
 * @example
 * <ShowMoreList numItemsToShow={3}>
 *   // array of child list items
 * </ShowMoreList>
 */
const ShowMoreList: ShowMoreListType = React.forwardRef<HTMLDivElement, Props>(
  function ShowMoreList(
    {
      numItemsToShow = 1,
      children,
      onChange,
      controllerProps,
      className,
      id,
      ...restProps
    },
    ref
  ) {
    const stringFormatter = useLocalizedStringFormatter(i18nMessages);
    const [expanded, setExpanded] = useState(false);

    // keep track of a keyboard interaction with the CTA
    // so we can manually manage focus, see useEffect()
    const isKeyboardEvent = useRef<boolean>(false);

    const firstExpandedRef = React.createRef<HTMLDivElement>();

    const baseId = useId(id);
    const listContainerId = `${baseId}-listContainer`;
    const expandedDescriptorId = `${baseId}-expanded-descriptor`;

    useEffect(() => {
      if (expanded && isKeyboardEvent.current) {
        // manually set focus on the first list item after expand to
        // preserve keyboard nav order
        firstExpandedRef?.current?.focus();
      }

      isKeyboardEvent.current = false;
    }, [expanded, firstExpandedRef, numItemsToShow, isKeyboardEvent]);

    const handleClick = () => {
      setExpanded((prevExpanded) => !prevExpanded);
      onChange?.(!expanded);
    };

    // convert `children` to a valid child element list.
    // note: the type assertion is needed because current TS version is unable to handle the
    // type conversion after the `filter` on `React.isValidElement` resulting in successive
    // TS errors like absence of `key` on each listItem (https://github.com/jsx-eslint/eslint-plugin-react/issues/1574)
    const listItems = React.Children.toArray(children).filter((child) =>
      React.isValidElement(child)
    ) as React.ReactElement[];

    // divide items into those which are shown in collapsed state,
    // and those that will transition in after expand.
    const collapsedItems = listItems.slice(0, numItemsToShow);
    const expandedItems = listItems.slice(numItemsToShow);
    const totalNumItems = listItems.length;
    // hide the CTA for some edge cases
    const shouldHideCta =
      totalNumItems === 0 || numItemsToShow >= totalNumItems;

    const itemTypeLabel =
      controllerProps?.itemTypePlural ?? stringFormatter.format('items');
    const collapsedLabel =
      controllerProps?.collapsedLabel ??
      stringFormatter.format('show_all', {
        count: totalNumItems,
        itemTypeLabel,
      });
    const expandedLabel =
      controllerProps?.expandedLabel ?? stringFormatter.format('show_less');

    // only show list aria-label when list is collapsed and has CTA
    const listAriaLabel =
      expanded || shouldHideCta
        ? undefined
        : stringFormatter.format('collapsed');

    return (
      <div
        ref={ref}
        id={baseId}
        {...restProps}
        className={className}
        css={getShowMoreListCss}
      >
        <VisuallyHidden
          aria-live="polite"
          id={expandedDescriptorId}
          tabIndex={-1}
        >
          {/* call out update when list is expanded, nothing to call out when collapsed */}
          {expanded
            ? stringFormatter.format('loaded_more', {
                count: children.length - numItemsToShow,
              })
            : ''}
        </VisuallyHidden>
        <div
          aria-label={listAriaLabel}
          className={clsx(classes.listContainer, {
            [classes.expanded]: expanded,
          })}
          id={listContainerId}
          role="list"
        >
          {collapsedItems.map((item, idx) => {
            return (
              <div
                key={item.key}
                aria-posinset={idx + 1}
                aria-setsize={totalNumItems}
                role="listitem"
              >
                {item}
              </div>
            );
          })}
          <Collapse className={classes.collapseContainer} in={expanded}>
            {/* transition in and out only the rest of the items when expanded/collapsed */}
            {expandedItems.map((item, idx) => {
              const isFirstExpandedItem = idx === 0;
              return (
                <div
                  key={item.key}
                  ref={isFirstExpandedItem ? firstExpandedRef : undefined}
                  aria-hidden={!expanded}
                  aria-posinset={numItemsToShow + idx + 1} // maintain position in total list
                  aria-setsize={totalNumItems}
                  className={clsx({
                    [classes.firstExpandedItem]: isFirstExpandedItem,
                  })}
                  role="listitem"
                  tabIndex={isFirstExpandedItem ? -1 : undefined}
                >
                  {item}
                </div>
              );
            })}
          </Collapse>
        </div>

        {!shouldHideCta && (
          <Button
            aria-controls={listContainerId}
            aria-expanded={expanded}
            aria-label={controllerProps?.ariaLabel}
            className={classes.ctaButton}
            icon={
              <ChevronNextIcon
                className={clsx(classes.ctaIcon, {
                  [classes.expanded]: expanded,
                })}
                size="large"
              />
            }
            iconPosition="before"
            size="small"
            variant="ghost"
            onClick={handleClick}
            onKeyDown={() => {
              isKeyboardEvent.current = true;
            }}
          >
            {expanded ? expandedLabel : collapsedLabel}
          </Button>
        )}
      </div>
    );
  }
);

export default ShowMoreList;
