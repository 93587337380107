/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { XdpV1_Org_Coursera_Xdp_Common_XdpRatings as Rating } from '__generated__/graphql-types-do-not-use';
import classNames from 'classnames';

import { Link, Typography2 } from '@coursera/cds-core';

import { TrackedA } from 'bundles/page/components/TrackedLink2';
import StarRatings, { StarSize } from 'bundles/reviews-common/components/cds/StarRatings';
import { getEnrollmentText } from 'bundles/video-logged-out-page/utils/utils';

import _t from 'i18n!nls/video-logged-out-page';

const styles = {
  ratingData: css`
    padding-left: 0.5rem;
    padding-bottom: 2px;
    position: relative;
    top: 1px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      padding: 0;
    }
  `,
  ratingInfoCourseEnrollment: css`
    @media (max-width: 767px) {
      display: block;
    }
  `,
  courseTotalRatings: css`
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: normal;
    }
  `,
};
export type Props = {
  rootClassName?: string;
  ratings: Rating;
  totalReviewCount: number;
  courseEnrollmentCount?: number | null;
  productLink?: string;
  a11yIdentifier?: string;
};

type RatingDataProps = {
  avgRatingRounded: number;
  ratingCountInLocale: string;
  ariaLabel?: string;
  translatedCourseEnrollmentText: React.ReactNode;
};

const RatingData = ({
  avgRatingRounded,
  ratingCountInLocale,
  ariaLabel,
  translatedCourseEnrollmentText,
}: RatingDataProps) => {
  if (!avgRatingRounded || !ratingCountInLocale) {
    return null;
  }

  return (
    <div aria-label={ariaLabel} css={styles.ratingData}>
      <Typography2 component="p" variant="bodySecondary">
        {_t('#{avgRatingRounded} (#{ratingCountInLocale} ratings)', { avgRatingRounded, ratingCountInLocale })}
      </Typography2>
      {translatedCourseEnrollmentText && <Typography2 component="p">&nbsp;|&nbsp;</Typography2>}
      {translatedCourseEnrollmentText && (
        <Typography2 component="p" variant="bodySecondary" css={styles.ratingInfoCourseEnrollment}>
          {translatedCourseEnrollmentText}
        </Typography2>
      )}
    </div>
  );
};

const CourseTotalRatings: React.FC<Props> = ({
  ratings,
  productLink,
  totalReviewCount,
  courseEnrollmentCount,
  rootClassName,
  a11yIdentifier,
}) => {
  if (!ratings || !totalReviewCount) {
    return null;
  }

  const { averageFiveStarRating } = ratings;
  // We use the totalReviewCount instead of the ratingCount in ratings so that the individual rating counts per
  // star in the StarsInfoBarGraph add up to the correct number (since totalReviewCount is more up to date)
  const ratingCountInLocale = totalReviewCount.toLocaleString() || '';
  const avgRatingRounded = (averageFiveStarRating && Number(averageFiveStarRating.toFixed(1))) || 0;
  const translatedCourseEnrollmentText = courseEnrollmentCount ? getEnrollmentText(courseEnrollmentCount, true) : '';

  const label = _t(
    'Rated #{avgRatingRounded} out of 5 from #{ratingCountInLocale} ratings. #{translatedCourseEnrollmentText}.',
    {
      avgRatingRounded,
      ratingCountInLocale,
      translatedCourseEnrollmentText,
    }
  );

  const ratingData = productLink ? (
    <Link
      href={productLink}
      ariaLabel={label}
      trackingName="vlp_stars_and_ratings"
      target="_blank"
      rel="noopener noreferrer"
      data-e2e="stars-and-ratings"
      component={TrackedA}
      variant="quiet"
    >
      <RatingData
        avgRatingRounded={avgRatingRounded}
        ratingCountInLocale={ratingCountInLocale}
        translatedCourseEnrollmentText={translatedCourseEnrollmentText}
      />
    </Link>
  ) : (
    <RatingData
      ariaLabel={label}
      avgRatingRounded={avgRatingRounded}
      ratingCountInLocale={ratingCountInLocale}
      translatedCourseEnrollmentText={translatedCourseEnrollmentText}
    />
  );
  const rootClassNames = classNames(rootClassName);

  return (
    <div className={rootClassNames} css={styles.courseTotalRatings}>
      <div aria-hidden="true">
        <StarRatings size={StarSize.Small} a11yIdentifier={a11yIdentifier} rating={avgRatingRounded} />
      </div>
      {ratingData}
    </div>
  );
};

export default CourseTotalRatings;
