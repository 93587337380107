/** @jsxFrag React.Fragment */
import { css } from '@emotion/react';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import { getCmsLocaleString } from 'js/lib/language';
import { useParams } from 'js/lib/useRouter';

import withUserAgent from 'bundles/page/common/withUserAgent';
import type { PropsFromWithUserAgent } from 'bundles/page/common/withUserAgent';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type {
  HubPageContentFieldsFragment,
  ProgramHubPageContentQuery,
  ProgramHubPageContentQueryVariables,
} from 'bundles/premium-hub/__generated__/contentfulTypes';
import CertificateCategoryCards from 'bundles/premium-hub/components/certificates/CertificatesCategoryCards';
import TopCertificatesList from 'bundles/premium-hub/components/certificates/TopCertificatesList';
import Container from 'bundles/premium-hub/components/shared/Container';
import Faqs from 'bundles/premium-hub/components/shared/Faqs';
import HubBanner from 'bundles/premium-hub/components/shared/HubBanner';
import InfoCard from 'bundles/premium-hub/components/shared/InfoCard';
import LearnMore from 'bundles/premium-hub/components/shared/LearnMore';
import LearningCommunitySection from 'bundles/premium-hub/components/shared/LearningCommunitySection';
import ProductVariantCards from 'bundles/premium-hub/components/shared/ProductVariantCards';
import UnifiedInstructorsList from 'bundles/premium-hub/components/shared/UnifiedInstructorsList';
import VideoTestimonials from 'bundles/premium-hub/components/shared/VideoTestimonials';
import { DISPLAYED_TOP_PRODUCTS_COUNT, HubPages } from 'bundles/premium-hub/constants';
import { getTopCertificates } from 'bundles/premium-hub/queries';
import { getPremiumHubPageContent } from 'bundles/premium-hub/queries/contentfulQueries';
import type { CertificateProps } from 'bundles/premium-hub/types';
import { CourseraMetatags } from 'bundles/seo';
import HubPageGoogleSchemaMarkup from 'bundles/seo/components/HubPageGoogleSchemaMarkup';

import _t from 'i18n!nls/premium-hub';

type TopCertificatesQuery = { TopCertificatesQueryResponse?: { certificateList: Array<CertificateProps> } };

type PropsFromDataFetcher = {
  pageContent?: Maybe<HubPageContentFieldsFragment>;
  topCertificatesList?: Array<CertificateProps>;
};

type Props = PropsFromDataFetcher & PropsFromWithUserAgent;

const styles = {
  container: css`
    margin-top: var(--cds-spacing-800);
  `,
};

export const CertificatesHubPage = ({ pageContent, topCertificatesList, userAgent }: Props) => {
  if (!pageContent) {
    return null;
  }

  const {
    slug,
    pageHeading,
    pageSubheading,
    associatedProgramsHeading,
    productVariantCardsCollection,
    learnMoreInfoHeading,
    learnMoreCardsCollection,
    learningCommunityTitle,
    communityStatsCollection,
    faqSectionHeader,
    faqsCollection,
    extraInformation,
    videoTestimonialsHeader,
    primaryVideoTestimonialOverride,
    secondaryVideoTestimonialsOverrideCollection,
    instructorMosaicHeader,
    instructorsCollection,
    seoInformation,
    categoryLinksCollection,
  } = pageContent;

  const certificatesToShow = topCertificatesList?.slice(0, DISPLAYED_TOP_PRODUCTS_COUNT);

  return (
    <>
      <main data-test="CertificatesHubPage">
        <HubPageGoogleSchemaMarkup productList={certificatesToShow} />
        <CourseraMetatags
          metaNameAndProperties={{
            title: seoInformation?.metaTitle ?? undefined,
            description: seoInformation?.metaDescription ?? undefined,
            disableCourseraSuffix: !seoInformation?.enableCourseraSuffix,
            disableCourseraDescriptionSuffix: !seoInformation?.enableCourseraDescriptionSuffix,
            disableDescLimit: !seoInformation?.enableDescriptionLimit,
          }}
        />
        <HubBanner
          slug={slug}
          pageHeading={pageHeading}
          pageSubheading={pageSubheading}
          currentHubPage={HubPages.MainHubPage}
        />
        <Container css={styles.container}>
          <ProductVariantCards
            title={associatedProgramsHeading}
            productVariantCards={productVariantCardsCollection?.items}
          />
          <TopCertificatesList certificatesList={certificatesToShow} />

          <CertificateCategoryCards
            categoryLinks={categoryLinksCollection?.items}
            displayVariantCards={false}
            heading={_t('Explore more certificates by category')}
          />
          <LearnMore
            userAgent={userAgent}
            learnMoreInfoHeading={learnMoreInfoHeading}
            learnMoreCards={learnMoreCardsCollection?.items}
          />
          <VideoTestimonials
            videoTestimonialsHeader={videoTestimonialsHeader}
            primaryVideoTestimonialOverride={primaryVideoTestimonialOverride}
            secondaryVideoTestimonialsOverride={secondaryVideoTestimonialsOverrideCollection?.items}
            productTypes={[slug] as Array<string>}
          />
          <LearningCommunitySection
            learningCommunityTitle={learningCommunityTitle}
            communityStats={communityStatsCollection?.items}
          />
          <UnifiedInstructorsList
            instructorMosaicHeader={instructorMosaicHeader}
            instructors={instructorsCollection?.items}
          />
          <InfoCard userAgent={userAgent} marketingContent={extraInformation} />
        </Container>

        <Faqs faqSectionHeader={faqSectionHeader} faqs={faqsCollection?.items} />
      </main>
    </>
  );
};

const CertificatesHubPageDataFetcher = () => {
  const params = useParams();
  const isPreview = Number(params.preview ?? 0) === 1;

  const { data: pageContentData } = useQuery<ProgramHubPageContentQuery, ProgramHubPageContentQueryVariables>(
    getPremiumHubPageContent,
    {
      variables: {
        slug: 'certificates',
        locale: getCmsLocaleString(),
        preview: isPreview,
      },
      // Only send a request through the Preview API if the user can preview
      context: { clientName: isPreview ? 'contentfulPreviewGql' : 'contentfulGql' },
      errorPolicy: 'all',
      onError: (error) =>
        Sentry.captureException(error, {
          extra: {
            message: 'Error fetching certificates hub page content',
          },
        }),
    }
  );
  const pageContent = pageContentData?.programHubPageContentCollection?.items?.[0];

  const { data: topCertificatesData } = useQuery<TopCertificatesQuery, {}>(getTopCertificates, {
    errorPolicy: 'all',
    onError: (error) =>
      Sentry.captureException(error, {
        extra: {
          message: 'Error fetching top certificates',
        },
      }),
  });
  const topCertificatesList = topCertificatesData?.TopCertificatesQueryResponse?.certificateList;

  const Page = compose<Props, PropsFromDataFetcher>(
    Retracked.createTrackedContainer(() => ({
      namespace: {
        app: 'program_hub',
        page: 'certificates_main_hub',
      },
    })),
    withUserAgent()
  )(CertificatesHubPage);

  return <Page pageContent={pageContent} topCertificatesList={topCertificatesList} />;
};

export default CertificatesHubPageDataFetcher;
