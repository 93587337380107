/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useMemo } from 'react';

import Imgix from 'js/components/Imgix';
import { useLocation } from 'js/lib/useRouter';

import { Typography2 } from '@coursera/cds-core';

import { logo, numberOfProducts } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import type { EventingData } from 'bundles/search-common/components/filters/SearchFilterItem';
import SearchFilterItem from 'bundles/search-common/components/filters/SearchFilterItem';
import { IS_PART_OF_COURSERA_PLUS } from 'bundles/search-common/constants/filter';
import {
  useChooseCourseraLite,
  useOwnsCourseraLite,
  useOwnsCourseraPlus,
} from 'bundles/search-common/providers/LearnerProvider';
import type { SearchFilterOption } from 'bundles/search-common/types';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';

import _t from 'i18n!nls/search-common';

type Props = {
  name: string;
  showCPlusSupportText?: boolean;
  items?: SearchFilterOption[];
  addFilters?: (x0: string) => void;
  removeFilters?: (x0: string) => void;
  eventingData: EventingData;
  // Added for SERP Filters Redesign experiment
  isRefined?: boolean;
};

const styles = {
  filterContainer: (isFiltersRedesign: boolean) => css`
    margin-bottom: ${isFiltersRedesign ? '4' : '32'}px;

    .rc-ForCreditFilterCategory + & {
      margin-top: -32px;
    }
  `,
  courseraPlusFilter: css`
    display: flex;
    align-items: center;
  `,
  plusLogo: css`
    margin-right: 8px;
    border-radius: 4px;
  `,
  supportText: css`
    margin-left: 36px;
  `,
  countLabel: css`
    color: var(--cds-color-grey-600);
    margin-left: var(--cds-spacing-50);
  `,
};

const CourseraPlusFilterCategory = ({
  name,
  addFilters,
  removeFilters,
  items = [],
  showCPlusSupportText,
  eventingData,
  isRefined,
}: Props) => {
  const { query } = useLocation();
  const chooseLite = useChooseCourseraLite();
  const ownsCourseraLite = useOwnsCourseraLite();
  const ownsCourseraPlus = useOwnsCourseraPlus();
  const hasCourseraPlusSearchFilterParam = query?.[IS_PART_OF_COURSERA_PLUS] === 'true';
  const showCourseraPlusFilter =
    Boolean(ownsCourseraLite && !ownsCourseraPlus) || ownsCourseraPlus || hasCourseraPlusSearchFilterParam;

  const courseraPlusFilterItem = useMemo(
    () =>
      items.find((item) => item.label === 'true') || {
        isRefined: Boolean(isRefined),
        label: 'true',
        value: 'true',
        count: 0,
      },
    [items, isRefined]
  );

  if (!showCourseraPlusFilter) return null;

  const countLabel = <span css={styles.countLabel}>({courseraPlusFilterItem.count.toLocaleString()})</span>;

  const courseraPlusLabel = (
    <div css={styles.courseraPlusFilter}>
      <Imgix
        src={logo.PLUS_PILL_BLUE}
        alt={_t('Coursera Plus')}
        maxWidth={41}
        maxHeight={16}
        css={styles.plusLogo}
        aria-hidden={true}
      />
      <Typography2 variant="bodyPrimary" component="span">
        {_t('Coursera Plus')} {countLabel}
      </Typography2>
    </div>
  );

  const courseraLiteLabel = (
    <div css={styles.courseraPlusFilter}>
      <Imgix
        src={logo.LITE_PILL_BLUE}
        alt={_t('Coursera Lite')}
        maxWidth={41}
        maxHeight={16}
        css={styles.plusLogo}
        aria-hidden={true}
      />
      <Typography2 variant="bodyPrimary" component="span">
        {_t('Coursera Lite')} {countLabel}
      </Typography2>
    </div>
  );

  return (
    <div className="rc-CourseraPlusFilterCategory" css={styles.filterContainer(isInFilterTestVariant())}>
      <SearchFilterItem
        key={courseraPlusFilterItem.label}
        {...courseraPlusFilterItem}
        value={`${IS_PART_OF_COURSERA_PLUS}:${courseraPlusFilterItem.value}`}
        label={chooseLite ? courseraLiteLabel : courseraPlusLabel}
        filterCategoryName={name}
        addFilters={addFilters}
        removeFilters={removeFilters}
        customAriaLabel={_t('Coursera Plus')}
        eventingData={eventingData}
        {...(showCPlusSupportText &&
          !chooseLite && {
            supportText: _t(
              'Unlimited access to #{numberOfProducts} world-class learning programs with a monthly or annual subscription.',
              { numberOfProducts }
            ),
          })}
        {...(isRefined !== undefined && { isRefined })}
      />
    </div>
  );
};

export default CourseraPlusFilterCategory;
