/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import config from 'js/app/config';

import { Grid, Typography, breakpoints } from '@coursera/cds-core';

import { buildCanonicalUrl } from 'bundles/common/utils/urlUtils';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { ProductVariantCardsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import CategoryCard from 'bundles/premium-hub/components/shared/CategoryCard';

import _t from 'i18n!nls/premium-hub';

export type CertificateCategoryCardsProps = {
  categoryLinks?: Array<Maybe<ProductVariantCardsFragment>>;
  productVariantCards?: Array<Maybe<ProductVariantCardsFragment>>;
  displayVariantCards: boolean;
  heading?: string;
};

const styles = {
  heading: css`
    margin-bottom: var(--cds-spacing-200);
  `,
  categoryCardList: css`
    margin-bottom: var(--cds-spacing-400);

    ${breakpoints.down('sm')} {
      display: block;
    }
  `,
  categoryCardItem: css`
    ${breakpoints.down('sm')} {
      display: inline-block;
    }

    > div {
      /* The div added by TrackedLink2 needs to be full height of the parent to align the text correctly */
      height: 100%;
    }
  `,
};

const CertificateCategoryCards = ({
  categoryLinks = [],
  productVariantCards = [],
  displayVariantCards,
  heading,
}: CertificateCategoryCardsProps) => {
  const allCategoryLinks: Maybe<ProductVariantCardsFragment>[] = [
    ...categoryLinks,
    ...(productVariantCards && displayVariantCards ? productVariantCards : []),
  ];

  if (!allCategoryLinks || !allCategoryLinks.length) {
    return null;
  }

  // Filter out any null or undefined values, or entries that don't have a title and callToActionLink
  const filteredCategoryLinks: ProductVariantCardsFragment[] = allCategoryLinks.filter(
    (item: Maybe<ProductVariantCardsFragment>): item is ProductVariantCardsFragment =>
      !!item && 'title' in item && 'callToActionLink' in item
  );

  return (
    <div className="rc-CertificateCategoryCards">
      <Typography variant="h1semibold" css={styles.heading} component="h2">
        {heading || _t('Browse by category')}
      </Typography>
      <Grid container spacing={16} css={styles.categoryCardList} role="list" data-testid="degrees-category-card-list">
        {filteredCategoryLinks.map(({ title, callToActionLink }) => {
          if (!callToActionLink) return null;
          const href = callToActionLink?.includes(config.url.base)
            ? callToActionLink
            : buildCanonicalUrl(callToActionLink);
          return (
            <Grid item xs={12} key={title} css={styles.categoryCardItem} role="listitem" alignContent="center">
              <CategoryCard slug={callToActionLink || ''} callToActionLink={href} title={title || ''} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CertificateCategoryCards;
