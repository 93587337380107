import React, { useCallback, useEffect, useLayoutEffect } from 'react';

import { useForkRef } from '@material-ui/core/utils';

import { useListBox, getItemId } from '@react-aria/listbox';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import { getItemCount } from '@react-stately/collections';
import { useListState } from '@react-stately/list';
import type { ListProps } from '@react-stately/list';
import type { Node } from '@react-types/shared';

import {
  useFilter,
  useId,
  useLocalizedStringFormatter,
} from '@coursera/cds-common';

import PopoverBody from '@core/Popover/Body';
import Typography from '@core/Typography2';
import VisuallyHidden from '@core/VisuallyHidden';

import constants from './constants';
import Group from './Group';
import type { Props as HeaderProps } from './Header';
import Header from './Header';
import i18nMessages from './i18n';
import LoadingPlaceholder from './LoadingPlaceholder';
import Option from './Option';
import listBoxCss from './styles/listboxCss';
import { hasElementScrollbar } from './utils';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export type Props<OptionType> = AriaListBoxOptions<OptionType> & {
  children: ListProps<OptionType>['children'];
  autoFocus?: 'first' | 'last' | boolean;
  loading?: boolean;
  className?: string;

  headerProps: Omit<HeaderProps, 'searchProps'>;

  searchProps?: Pick<HeaderProps['searchProps'], 'placeholder' | 'aria-label'>;

  onSearchChange?: (value: string) => void;
};

type FilterFn = (textValue: string, inputValue: string) => boolean;

function ListBoxComponent<T extends object>(
  props: Props<T>,
  ref: React.Ref<HTMLUListElement>
) {
  const innerRef = React.useRef<HTMLUListElement>(null);
  const handleRef = useForkRef(ref, innerRef);
  const id = useId();
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const inputAriaDescribedBy = `${id}-options-number-label`;

  const [search, setSearch] = React.useState<string>('');
  const [width, setWidth] = React.useState<number | string | undefined>(
    undefined
  );

  const {
    loading,
    headerProps,
    searchProps,
    onSearchChange,
    ...listProps
  } = props;

  // Use 'base' sensitivity to make it case-insensitive
  const { contains, exact } = useFilter({ sensitivity: 'base' });

  // Filter items on our end when consumers don't provide an `onSearchChange` but there is a search query.
  // This avoids double filtering for when consumers do provide an `onSearchChange` for their own filtering logic, so that we can skip ours.
  const shouldFilter =
    search.length > 0 && typeof onSearchChange === 'undefined';

  const state = useListState({
    ...listProps,

    filter: shouldFilter
      ? (nodes): Iterable<Node<T>> => filterNodes(nodes, search, contains)
      : undefined,
  });

  const { listBoxProps } = useListBox(
    { autoFocus: 'first', ...listProps, id },
    state,
    innerRef
  );

  const itemCount = getItemCount(state.collection);

  const activeDescendant =
    state.selectionManager.focusedKey != null
      ? getItemId(state, state.selectionManager.focusedKey)
      : undefined;

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearch(value);

      onSearchChange?.(value);
    },
    [onSearchChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape' && search.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        handleSearchChange('');
      }
    },
    [search, handleSearchChange]
  );

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        innerRef.current?.focus();
      }
    },
    []
  );

  useIsomorphicLayoutEffect(() => {
    // Preserve initial width of the listbox so that it doesn't jump around when filtering options
    if (innerRef.current && headerProps.searchable && !headerProps.isDrawer) {
      const element = innerRef.current;

      const clientWidth = element.clientWidth;

      const computedStyle = window.getComputedStyle(element);

      const padding =
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight);
      let scrollBarOffset = 0;

      const hasScrollbar = hasElementScrollbar(element);

      if (hasScrollbar) {
        scrollBarOffset = element.offsetWidth - clientWidth;
      }

      // Only set when not loading, otherwise there might be options with wrapped text
      if (!loading) {
        setWidth(clientWidth - padding + scrollBarOffset);
      }
    }
  }, [innerRef.current, headerProps.searchable, headerProps.isDrawer, loading]);

  return (
    <>
      {(headerProps.isDrawer || headerProps.searchable) && (
        <Header
          {...props.headerProps}
          searchProps={{
            ...searchProps,
            value: search,
            onChange: handleSearchChange,
            onSearch: handleSearchChange,
            inputProps: {
              'aria-controls': id,
              'aria-describedby':
                // announce number of items if there is no search query, otherwise role=status will take over
                search.length === 0 ? inputAriaDescribedBy : undefined,
              'aria-activedescendant': activeDescendant,
              onKeyDown: handleKeyDown,
              onKeyUp: handleKeyUp,
            },
          }}
        />
      )}

      {/* Announced every time loading is happening or number of items has changed */}
      <VisuallyHidden role="status">
        {loading
          ? stringFormatter.format('loading')
          : stringFormatter.format('optionsAvailable', { number: itemCount })}
      </VisuallyHidden>

      {/* Announced when a user lands on field for the first time. Role status is not announced on mount.
      Safari has an issue when it's not getting announced after field has been cleared out. */}
      <VisuallyHidden aria-hidden id={inputAriaDescribedBy}>
        {loading
          ? stringFormatter.format('loading')
          : stringFormatter.format('optionsAvailable', { number: itemCount })}
      </VisuallyHidden>

      {!loading ? (
        <PopoverBody
          ref={handleRef}
          className={props.className}
          component="ul"
          css={listBoxCss}
          {...listBoxProps}
          style={{
            width,
            maxHeight:
              headerProps.searchable && !headerProps.isDrawer
                ? constants.SEARCHABLE_LISTBOX_HEIGHT
                : undefined,
            minHeight:
              headerProps.searchable && !headerProps.isDrawer
                ? constants.SEARCHABLE_LISTBOX_HEIGHT
                : undefined,
          }}
          // Make it not focusable for user if there is no option available
          tabIndex={itemCount === 0 ? -1 : 0}
        >
          {itemCount === 0 && (
            <li role="presentation">
              <Typography
                align="center"
                color="supportText"
                component="div"
                variant="bodySecondary"
              >
                {headerProps.searchable
                  ? stringFormatter.format('noSearchResults', {
                      searchText: search,
                    })
                  : stringFormatter.format('noOptionsAvailable')}
              </Typography>
            </li>
          )}
          {Array.from(state.collection).map((item) => {
            if (item.type === 'section') {
              return (
                <Group
                  key={item.key}
                  comparator={exact}
                  group={item}
                  searchQuery={search}
                  state={state}
                />
              );
            }

            return (
              <Option
                key={item.key}
                comparator={exact}
                item={item}
                searchQuery={search}
                state={state}
                suffix={item.props.suffix}
                supportText={item.props.supportText}
              />
            );
          })}
        </PopoverBody>
      ) : (
        <LoadingPlaceholder ref={handleRef} style={{ width }} />
      )}
    </>
  );
}

/**
 * Recursively filters collection nodes
 * @param nodes
 * @param inputValue
 * @param filter
 */
export function filterNodes<T>(
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn
): Iterable<Node<T>> {
  const filteredNode = [];

  for (const node of nodes) {
    if (node.type === 'section' && node.hasChildNodes) {
      const filtered = filterNodes(node.childNodes, inputValue, filter);
      if ([...filtered].some((node) => node.type === 'item')) {
        filteredNode.push({ ...node, childNodes: filtered });
      }
    } else if (node.type === 'item' && filter(node.textValue, inputValue)) {
      filteredNode.push({ ...node });
    } else if (node.type !== 'item') {
      filteredNode.push({ ...node });
    }
  }
  return filteredNode;
}

// forwardRef doesn't support generic parameters, so cast the result to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const ListBox = React.forwardRef(ListBoxComponent) as <T extends object>(
  props: Props<T> & { ref?: React.Ref<HTMLUListElement> }
) => ReturnType<typeof ListBoxComponent>;

export default ListBox;
