import type { DegreeMarketing_MarketingType as MarketingType } from '__generated__/graphql-types';

import type {
  AssociatedProgramsSectionProps,
  BannerProps,
  CategoryIntroProps,
  CategoryLinkCardProps,
  ExpressionOfInterestProps,
  ExtraInformationProps,
  FaqsSectionProps,
  InstructorsSectionProps,
  LearnMoreSectionProps,
  LearningCommunitySectionProps,
  MetaProps,
  SeoInformationProps,
  VideoTestimonialsSectionProps,
} from 'bundles/cms/types';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type {
  PremiumHubAboveTheFoldFieldsFragment,
  PremiumHubBelowTheFoldFieldsFragment,
  ProductVariantCardsFragment,
} from 'bundles/premium-hub/__generated__/contentfulTypes';
import type { PremiumHubPageType, UnifiedHubProductVariant } from 'bundles/premium-hub/constants';
import type { ValuesFromPremiumHubPageData } from 'bundles/premium-hub/hooks/usePremiumHubPageData';
import type {
  GetPremiumProductCollectionsInPremiumHub_PartnerLocationFragment as PartnerLocation,
  GetPremiumProductCollectionsInPremiumHub_PremiumProductUpcomingTermDatesFragment as PremiumProductUpcomingTermDates,
} from 'bundles/premium-hub/queries/__generated__/GetPremiumProductCollectionsInPremiumHubQuery';
import type {
  StackableCourseItemFragment,
  StackableSpecializationItemFragment,
} from 'bundles/premium-hub/queries/__generated__/GetStackableContentByDegreeIds';
import type { PremiumProductFieldsFragment } from 'bundles/premium-hub/queries/__generated__/PremiumProductFieldsFragment';
import type { getAllDomains_DomainsV1Resource_domains_elements as DomainsV1ResourceElement } from 'bundles/premium-hub/queries/__generated__/getAllDomains';
import type { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

export type Domain = DomainsV1ResourceElement;

type $Values<O extends Record<string, unknown>> = O[keyof O];

export default {};

export const AVAILABILITY_STATUS = { LIVE: 'LIVE', PRE_LAUNCH: 'PRE_LAUNCH', ARCHIVED: 'ARCHIVED' };
export type AvailabilityStatus = $Values<typeof AVAILABILITY_STATUS>;

export const PRODUCT_TYPE = { DEGREE: 'PRODUCT_TYPE~DEGREE', MASTERTRACK: 'PRODUCT_TYPE~MASTERTRACK' };

export type PPCDegreeCategory = {
  id: string;
  slug: string;
  degreeCount: number;
};

export type DegreesV1Connection = {
  elements: Array<Degree>;
};

export type Partner = {
  id: string;
  name: string;
  squareLogo?: string | null;
  shortName?: string | null;
  primaryColor?: string | null;
  location?: PartnerLocation | null;
  __typename?: string;
};

export type PartnersV1Connection = {
  elements: Array<Partner>;
};

export type UpcomingTermDates = {
  nextAcademicTermStartDate?: number;
  nextAdmissionDeadlineDate?: number;
  nextAdmissionStartDate?: number;
  nextAdmissionSubtermDeadlineDate?: number;
};

export type Degree = {
  id?: string;
  slug: string;
  name?: string;
  bannerImage?: string;
  marketingName?: string;
  catalogAvailabilityStatus: AvailabilityStatus;
  partners?: PartnersV1Connection;
  productVariant?: string;
  domainIds?: Array<string>;
  languageCode?: string;
  degreeUpcomingTermDates?: UpcomingTermDates;
};

export type AtAGlanceProps = {
  timeFrame?: string;
  tuition?: string;
  ranking?: string;
  location?: string;
};

export type AtAGlanceQueryResponse = {
  universityProgramPageContentCollection?: {
    items?: [
      {
        atAGlance: AtAGlanceProps;
      }
    ];
  };
};

export type CertificateProps = {
  productVariant: (typeof UnifiedHubProductVariant)[keyof typeof UnifiedHubProductVariant];
  id: string;
  slug: string;
  name: string;
  partner?: {
    id: string;
    name: string;
    squareLogo: string;
  };
  categories?: Array<string>;
};

type CertificateContentfulData = {
  enrollmentDate: string;
  timeFrame: string;
  programDescriptionSubtitle: string;
  lastPublishedAt?: string;
  firstPublishedAt?: string;
  language?: string;
  tagline?: string;
};

export type CertificatePropsWithContentfulData = CertificateProps & CertificateContentfulData;

type CertificateCatalogMastertrackData = {
  bannerImage?: string;
  languageCode: string;
  domainIds: Array<Maybe<string>>;
};

export type CertificateWithMetadata = CertificateProps &
  CertificateContentfulData &
  CertificateCatalogMastertrackData & {
    enrollmentDateEpochTime?: number;
    durationGroup?: string;
    popularity?: number;
  };

export type ProductCardProps = {
  id?: string;
  productVariant: (typeof UnifiedHubProductVariant)[keyof typeof UnifiedHubProductVariant];
  slug?: string;
  name?: string;
  partner?: {
    id: string;
    name: string;
    squareLogo: string;
    partnerMonotoneLogo?: string;
    partnerLogo?: string;
    country?: string;
  };
  description?: string;
  duration?: string;
  degreeLevel?: string;
  bannerImage?: string;
  upcomingTermDates?: PremiumProductUpcomingTermDates;
  timeFrame?: string;
  programDeadlines?: ProgramDealines;
  location?: string | null;
  degreeAttributes?: {
    hasScholarship?: boolean | null;
    tuitionInUsd?: number | null;
  };
};

export type UnifiedProductCardProps = {
  id?: string;
  title?: string;
  slug?: string;
  bannerImage?: string;
  difficultyLevel?: string;
  productVariant?: string;
  partnerInfo?: {
    partnerName?: string;
    partnerLogo?: string;
    country?: string;
  };
  programTimeFrame?: string | null;
  programSummary?: string | null;
  programDeadlineText?: string | null;
  languageCode?: string;
  language?: string;
};

export type ProductOfferingCardLinkProps = {
  id?: string;
  name?: string;
  slug: string;
  title: string;
  url: string;
  productName?: string;
  partnerInfo?: {
    partnerName?: string;
    partnerLogo?: string;
    partnerText?: string;
  };
  programDetails: string;
  programSummary?: string;
  programDeadlineText?: string;
  linkText: string;
  languageCode?: string;
};

export type ProductOfferingCardProps = {
  name?: string;
  title: string;
  partnerInfo?: {
    partnerName?: string;
    partnerLogo?: string;
    partnerText?: string;
  };
  programDetails: string;
  programSummary?: string;
  programDeadlineText?: string;
  linkText: string;
};

type SeoProps = {
  seoInformation: SeoInformationProps;
};

export type PageContentProps = MetaProps &
  SeoProps &
  BannerProps &
  AssociatedProgramsSectionProps &
  CategoryIntroProps &
  LearnMoreSectionProps &
  VideoTestimonialsSectionProps &
  LearningCommunitySectionProps &
  InstructorsSectionProps &
  FaqsSectionProps &
  ExtraInformationProps &
  ExpressionOfInterestProps & {
    categoryLinks: Array<CategoryLinkCardProps>;
    productVariantCards?: Array<Maybe<ProductVariantCardsFragment>>;
  };

export type DegreeContentfulMetadata = {
  slug?: string | null;
  atAGlance?: {
    timeFrame?: string | null;
    ranking?: string | null;
    location?: string | null;
  } | null;
  partnerContentful?: {
    partnerMonotoneLogo?: {
      url?: string | null;
    };
    partnerLogo?: {
      url?: string | null;
    };
  } | null;
} | null;

export type DegreeWithMetadata = Degree & DegreeContentfulMetadata;
export type PremiumProductWithMetadata = PremiumProductProps & DegreeContentfulMetadata;

export type CategoryData = {
  name: string;
  slug: string;
  id: string;
};

export type DegreeCardV2Props = {
  title: string;
  partnerInfo?: {
    partnerName?: string;
    partnerLogo?: string;
  };
  programCountry?: string | null;
  programLanguage?: string | null;
  programTimeFrame?: string | null;
  programSummary?: string | null;
  programDeadlineText?: string | null;
  languageCode?: string;
};

export type DegreeCardDegreeProps = {
  slug: string;
  id?: string;
  name: string;
  bannerImage?: string | null;
  marketingName?: string;
  partners?: {
    elements: Partner[];
  };
  partner?: Partner | null;
  type?: string;
  link?: string;
};

export type PremiumProductProps = PremiumProductMember &
  DegreeCardDegreeProps & {
    productVariant?: string | null;
    upcomingTermDates?: PremiumProductUpcomingTermDates | null;
  };

export type ProgramDealines = NonNullable<PremiumProductFieldsFragment['programDeadlines']>;

export type PremiumProductMember = {
  id?: string;
  name: string;
  shortName?: string;
  marketingName?: string;
  slug: string;
  bannerImage?: string | null;
  partnerIds: Array<string | number>;
  domainIds: Array<string>;
  type?: string;
  link?: string;
  partner?: Partner | null;
  partners?: {
    elements: Partner[];
  };
  degreeAttributes?: {
    hasScholarship?: boolean | null;
    tuitionInUsd?: number | null;
  };
  categoryIds?: Array<string | null>;
  catalogAvailabilityStatus?: string | null;
  languageCode?: string | null;
  programDeadlines?: ProgramDealines;
  __typename?: string;
};

export type ValidationErrors = {
  phoneNumber?: string;
  degreeTrack?: string;
  categoriesSelection?: string;
};

export type Orientation = 'vertical' | 'horizontal';

export type PremimumHubPageContent = PremiumHubAboveTheFoldFieldsFragment &
  Partial<PremiumHubBelowTheFoldFieldsFragment>;

export type EoiProductType = keyof typeof ProductTypes;

export type NavigationItem = {
  id: string;
  name: string;
  isIndexPage?: boolean;
};

export type PropsFromHubPageContent = ValuesFromPremiumHubPageData & {
  shouldShowDriftChat: boolean;
  currentPageType: PremiumHubPageType;
  inBrowse?: boolean;
};

export type ContentfulVflpConfigProps = {
  ppcFilters: Maybe<{
    categories: Maybe<string[]>;
    domains: Maybe<string[]>;
    productVariants: Maybe<string[]>;
  }>;
  byMarketingTypeFilter: Maybe<MarketingType>;
  customIds: Maybe<string[]>;
  algoliaFilters: Maybe<string[]>;
};

export type StackableItem = (StackableSpecializationItemFragment | StackableCourseItemFragment) & {
  productType: 'Specialization' | 'Course';
  xdpUrl: string;
};
