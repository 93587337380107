import type React from 'react';

import { Item, Section } from '@react-stately/collections';

export type OptionProps = {
  /**
   * Additional context for an option.
   */
  supportText?: string;

  /**
   * Additional element rendered at the end of option element.
   */
  suffix?: React.ReactNode;

  /**
   * An accessibility label for this option.
   */
  'aria-label'?: string;

  /**
   * Option label.
   */
  children: string;
};

const Option = Item as (props: OptionProps) => React.ReactElement;

export type OptionElement = React.ReactElement<OptionProps>;
export type OptionRenderer<T> = (item: T) => OptionElement;

export type GroupProps<T> = {
  /**
   * Group title.
   */
  title: string;

  /**
   * An accessibility label for the group.
   */
  'aria-label'?: string;

  /**
   * Static child items or a function to render children.
   */
  children: OptionElement | OptionElement[] | OptionRenderer<T>;

  /**
   * Option objects in the group.
   */
  items?: Iterable<T>;
};

const Group = Section as <T>(props: GroupProps<T>) => React.ReactElement;

export { Option, Group };
