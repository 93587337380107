/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { useTheme } from '@coursera/cds-core';

import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import HubBreadcrumb from 'bundles/premium-hub/components/shared/HubBreadcrumb';
import { PRODUCT_TYPE_TO_SLUG } from 'bundles/premium-hub/constants';
import type { ProductType } from 'bundles/premium-hub/constants';

type Props = {
  productName?: Maybe<string>;
  productType?: (typeof ProductType)[keyof typeof ProductType];
  inHeroBannerTest?: boolean;
};

const HubBreadcrumbs = ({ productName, productType, inHeroBannerTest = false }: Props) => {
  const theme = useTheme();

  if (!productType) {
    return null;
  }

  const mainHubLink = `/${PRODUCT_TYPE_TO_SLUG[productType]}`;
  const breadcrumbLinks = [
    {
      href: mainHubLink,
      label: productType,
    },
    {
      label: productName,
    },
  ];

  return (
    <div
      data-test="HubBreadcrumbs"
      css={{ marginBottom: inHeroBannerTest ? 'var(--cds-spacing-100)' : 'var(--cds-spacing-400)' }}
      role="navigation"
      aria-label="Breadcrumb"
    >
      <ol
        css={{
          listStyleType: 'none',
          padding: '0',
          margin: '0',
        }}
      >
        {breadcrumbLinks.map(({ href, label }) => (
          <HubBreadcrumb key={label} href={href} label={label} />
        ))}
      </ol>
    </div>
  );
};

export default HubBreadcrumbs;
