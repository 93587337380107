/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo, useCallback, useMemo, useState } from 'react';

import { Button } from '@coursera/cds-core';
import { FilterIcon } from '@coursera/cds-icons';

import { useTracker } from 'bundles/page/lib/event-pulse/react';
import SearchFiltersMobileModal from 'bundles/search-common/components/filters/SearchFiltersMobileModal';
import type { FilterConfig } from 'bundles/search-common/components/filters/getFiltersList';
import type { SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

type Props = {
  filtersList: FilterConfig[];
  showCPlusSupportText?: boolean;
  filters?: Record<string, SearchFilterOption[] | undefined>;
  activeFilters?: string[];
  filtersLoading?: boolean;
  eventingData: {
    searchIndex: string;
    searchIndexPosition: number;
  };
  addFilters: (filters: string) => void;
  removeFilters: (filters: string) => void;
  clearFilters: () => void;
};

const styles = {
  mobileContainer: css`
    min-height: 36px;
    margin-bottom: 24px;
  `,
};

const disableScrolling = (disabled: boolean) => {
  const bodyStyle = document?.body?.style;
  if (bodyStyle) {
    bodyStyle.overflow = disabled ? 'hidden' : 'visible';
  }
};

const SearchFiltersMobile = ({
  filters,
  filtersList,
  showCPlusSupportText,
  eventingData,
  activeFilters,
  filtersLoading,
  addFilters,
  removeFilters,
  clearFilters,
}: Props) => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const trackV3 = useTracker();

  const handleAddFilters = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (_filters: string) => {
      trackV3('apply_filter', {
        filter: [_filters],
        filterSource: 'mobile_modal',
      });
      addFilters?.(_filters);
    },
    [trackV3, addFilters]
  );

  const handleRemoveFilters = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (_filters: string) => {
      trackV3('apply_filter', {
        filter: [],
        filterSource: 'mobile_modal',
      });
      removeFilters?.(_filters);
    },
    [trackV3, removeFilters]
  );

  const handleClearFilters = useCallback<() => $TSFixMe>(() => {
    trackV3('apply_filter', {
      filter: [],
      filterSource: 'mobile_modal',
      isReset: true,
    });
    clearFilters?.();
  }, [trackV3, clearFilters]);

  const toggleMobileFilterMenu = () => {
    disableScrolling(!isMobileModalOpen);
    setIsMobileModalOpen(!isMobileModalOpen);

    window.scroll(0, 0);
  };

  const activeFiltersCount = useMemo(() => activeFilters?.length || 0, [activeFilters]);

  if (filtersLoading) {
    return <div css={styles.mobileContainer} />;
  }

  return (
    <div css={styles.mobileContainer}>
      <Button
        size="small"
        variant="secondary"
        icon={<FilterIcon size="small" />}
        iconPosition="after"
        onClick={toggleMobileFilterMenu}
      >
        {activeFiltersCount > 0 ? _t('Filters (#{activeFiltersCount})', { activeFiltersCount }) : _t('Filters')}
      </Button>
      <SearchFiltersMobileModal
        toggleMobileFilterMenu={toggleMobileFilterMenu}
        filtersList={filtersList}
        addFilters={handleAddFilters}
        removeFilters={handleRemoveFilters}
        clearFilters={handleClearFilters}
        isHidden={!isMobileModalOpen}
        showCPlusSupportText={showCPlusSupportText}
        filters={filters}
        eventingData={eventingData}
      />
    </div>
  );
};

export default memo(SearchFiltersMobile);
