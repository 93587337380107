import React from 'react';

import clsx from 'clsx';

import { useId } from '@coursera/cds-common';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import ButtonBase from '@core/ButtonBase';
import type { ButtonBaseProps } from '@core/ButtonBase';
import Typography from '@core/Typography2';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

import getSelectControllerCss, { classes } from './styles/selectControllerCss';

type AriaLabelledbyFunc = (ids: {
  placeholderId: string;
  counterId: string;
}) => string;

export type Props = {
  placeholder?: string;
  active?: boolean;
  variant?: 'outline' | 'silent';
  width?: number;
  fullWidth?: boolean;
  value?: string;
  valueProps?: React.HTMLAttributes<HTMLSpanElement>;
  readOnly?: boolean;
  'aria-labelledby'?: string | AriaLabelledbyFunc;
  children?: (props: {
    value?: string;
    placeholder?: string;
  }) => React.ReactNode;
} & Omit<ButtonBaseProps, 'aria-labelledby' | 'children'>;

/**
 * Renders input like button
 */
const SelectController = React.forwardRef<HTMLButtonElement, Props>(
  function Controller(props, ref) {
    const {
      id: idProps,
      active,
      placeholder,
      className,
      width,
      variant = 'outline',
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      value,
      children,
      fullWidth,
      onKeyDown,
      readOnly,
      valueProps,
      ...restProps
    } = props;
    const css = getSelectControllerCss({ width });
    const id = useId(idProps);
    const placeholderId = `${id}-placeholder`;
    const counterId = `${id}-counter`;

    // Add the selected items count to the aria label if in multiple selection mode
    const ariaLabelValue = ariaLabelledByForMultipleLabels(
      placeholder,
      ariaLabel
    );

    // Prevent updating the value via the keyboard if the field is read-only
    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (readOnly && ['ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.preventDefault();
        return;
      }
      onKeyDown?.(e);
    };

    return (
      <ButtonBase
        ref={ref}
        aria-expanded={active}
        aria-haspopup="listbox"
        aria-label={ariaLabelValue ?? undefined}
        aria-labelledby={
          typeof ariaLabelledby === 'function'
            ? ariaLabelledby({ placeholderId, counterId })
            : ariaLabelledby
        }
        className={clsx(className, {
          [classes.active]: active,
          [classes.outlined]: variant === 'outline',
          [classes.silent]: variant === 'silent',
          [classes.fullWidth]: fullWidth,
          [classes.readOnly]: readOnly,
        })}
        css={css}
        id={id}
        {...restProps}
        onKeyDown={handleKeyDown}
      >
        {typeof children === 'function' ? (
          children({ value, placeholder })
        ) : (
          <Typography
            {...valueProps}
            className={!value && placeholder ? classes.label : classes.value}
            color="inherit"
            component="span"
          >
            {value ?? placeholder}
          </Typography>
        )}

        <span className={classes.icon}>
          {active ? (
            <ChevronUpIcon
              color="default"
              data-testid="icon-active"
              size="large"
            />
          ) : (
            <ChevronDownIcon
              color="default"
              data-testid="icon-not-active"
              size="large"
            />
          )}
        </span>
      </ButtonBase>
    );
  }
);

export default SelectController;
