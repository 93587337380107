/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import type { CommunityStatsFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';

// This hides an error with bgImage being undefined
const styles: Record<string, CSSProperties> = {
  learningCommunityCard: (backgroundImage: string) => ({
    height: 278,
    minHeight: 242,
    margin: '10px 5px',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundImage: `url("${backgroundImage}")`,
  }),
  learnerInfo: (theme: Theme) => ({
    padding: 'var(--cds-spacing-150)',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: 'var(--cds-color-grey-975)',
  }),
  learnerName: (theme: Theme) => ({
    margin: '0',
    color: 'var(--cds-color-white-0)',
  }),
  enrolledProgram: (theme: Theme) => ({
    marginBottom: 'var(--cds-spacing-100)',
    color: 'var(--cds-color-white-0)',
  }),
};

const LearnerProfile = ({ name, profilePicture, enrolledProgram }: CommunityStatsFragment) => {
  const theme = useTheme();

  const bgImage = profilePicture?.url || profilePicture;

  return (
    <div css={styles.learningCommunityCard(bgImage)}>
      <div css={styles.learnerInfo(theme)}>
        <Typography2 variant="bodyPrimary" component="p" css={styles.learnerName(theme)}>
          <strong>{name}</strong>
        </Typography2>
        <Typography2 variant="bodyPrimary" component="p" css={styles.enrolledProgram(theme)}>
          <i>{enrolledProgram}</i>
        </Typography2>
      </div>
    </div>
  );
};

export default LearnerProfile;
