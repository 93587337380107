/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, breakpoints } from '@coursera/cds-core';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { LearnMoreValuePropositionUnifiedFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import Container from 'bundles/premium-hub/components/shared/Container';
import ValueProposition from 'bundles/premium-hub/components/shared/ValueProposition';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import useCoursesAndSpecializations from 'bundles/premium-hub/hooks/useCoursesAndSpecializations';
import type { PremiumProductWithMetadata } from 'bundles/premium-hub/types';
import {
  consolidateCourseSlugsFromMultipleItems,
  consolidateS12nSlugsFromMultipleItems,
  filterNullOrUndefined,
} from 'bundles/premium-hub/utils';

/*
  This function will populate the relatedProgramSlugs field for each value proposition if it is not already populated.
  It also ensures that a different set of recommendations is used for each section.
*/
const formatValuePropositions = (
  valuePropositions: Array<LearnMoreValuePropositionUnifiedFragment | null>,
  degreesListWithMetadata?: Array<PremiumProductWithMetadata>
): Array<LearnMoreValuePropositionUnifiedFragment | null> => {
  const recommendationsToDisplay = 4;
  let recommendationsIndex = 0;
  return valuePropositions.map((valueProposition) => {
    if (!valueProposition) return null;

    const { ...valueProp } = valueProposition;

    // Populate the slugs from DRE recommendations if there aren't any from Contentful, ensure we use different recommendations for each section
    if (!valueProp.relatedProgramSlugs?.length && degreesListWithMetadata?.length) {
      valueProp.relatedProgramSlugs =
        degreesListWithMetadata
          ?.slice(recommendationsIndex, recommendationsIndex + recommendationsToDisplay)
          ?.map((degree) => degree.slug) ?? [];
      recommendationsIndex += recommendationsToDisplay;
    }

    return valueProp;
  });
};

type Props = {
  heading?: string;
  valuePropositions?: Array<LearnMoreValuePropositionUnifiedFragment | null>;
  degreeListWithMetadata?: Array<PremiumProductWithMetadata>;
  currentPageType: PremiumHubPageType;
};

const styles = {
  title: css`
    margin: var(--cds-spacing-300) 0;

    ${breakpoints.up('md')} {
      margin: var(--cds-spacing-600) 0;
    }
  `,
};

const ValuePropositions = ({ heading, valuePropositions, degreeListWithMetadata, currentPageType }: Props) => {
  const courseSlugs = consolidateCourseSlugsFromMultipleItems(valuePropositions?.filter(filterNullOrUndefined));
  const s12nSlugs = consolidateS12nSlugsFromMultipleItems(valuePropositions?.filter(filterNullOrUndefined));
  const { relatedCourses, relatedS12ns } = useCoursesAndSpecializations(courseSlugs, s12nSlugs);
  if (!valuePropositions || !valuePropositions.length || !degreeListWithMetadata?.length) {
    return null;
  }

  const formattedValueProps = formatValuePropositions(valuePropositions, degreeListWithMetadata);

  return (
    <div>
      {heading && (
        <Container>
          <TrackedDiv
            trackingName="learn_more_value_propositions_heading"
            withVisibilityTracking={true}
            atMostOnce={true}
            trackClicks={false}
          >
            <Typography component="h2" variant="h1semibold" css={styles.title}>
              {heading}
            </Typography>
          </TrackedDiv>
        </Container>
      )}
      {/* The empty div wrapper ensures the :nth-of-type selector only effects the value propositions and excludes the title */}
      <div>
        {formattedValueProps.map((content, index) => (
          <ValueProposition
            key={content?.sys.id}
            marketingContent={content}
            degreeListWithMetadata={degreeListWithMetadata}
            subindex={index + 1}
            currentPageType={currentPageType}
            relatedCourses={relatedCourses}
            relatedS12ns={relatedS12ns}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(ValuePropositions);
