/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';

import config from 'js/app/config';
import logger from 'js/app/loggerSingleton';
import { useLocation, useNavigate } from 'js/lib/useRouter';
import { isAuthenticatedUser, get as userGet } from 'js/lib/user';

import { Dialog, breakpoints } from '@coursera/cds-core';
import type { EoiSection } from '@coursera/event-pulse-types';

import type { ProfilePhone } from 'bundles/account-profile/components/types';
import * as actions from 'bundles/authentication/shared/actions';
import { shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import { getQualifyingQuestionsExpt } from 'bundles/degree-description/utils/experiments';
import { useChiliPiper } from 'bundles/expression-of-interest/components/ChiliPiper/ChiliPiperProvider';
import type { ChiliPiperInfo } from 'bundles/expression-of-interest/components/ChiliPiper/ChiliPiperProvider';
import ConfirmationSection from 'bundles/expression-of-interest/components/ConfirmationSection';
import { useEoiOptimizationsContext } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import InitialSection from 'bundles/expression-of-interest/components/InitialSection';
import LoginSection from 'bundles/expression-of-interest/components/LoginSection';
import PhoneNumberSection from 'bundles/expression-of-interest/components/PhoneNumberSection';
import SelectSubjectSection from 'bundles/expression-of-interest/components/SelectSubjectSection';
import NextStepsSection from 'bundles/expression-of-interest/components/experiments/NextStepsSection';
import QualifyingQuestionsSection from 'bundles/expression-of-interest/components/experiments/QualifyingQuestionsSection';
import { isLearnerQualified } from 'bundles/expression-of-interest/components/experiments/qualifyingQuestionsUtils';
import { ACTION_QUERY_PARAMS, CONTINUE_EOI_PARAMS } from 'bundles/expression-of-interest/constants';
import { useDegreeProgramAdmissionDeadlines } from 'bundles/expression-of-interest/hooks/useDegreeProgramAdmissionDeadlines';
import { CREATE_OR_UPDATE } from 'bundles/expression-of-interest/queries/CreateOrUpdateQualificationSurveySectionResponse';
import type {
  CreateOrUpdateResponse,
  CreateOrUpdateVariables,
} from 'bundles/expression-of-interest/queries/CreateOrUpdateQualificationSurveySectionResponse';
import type {
  PreviewStartSectionResponse,
  PreviewStartSectionVariables,
} from 'bundles/expression-of-interest/queries/PreviewStartSection';
import { PREVIEW_START_SECTION } from 'bundles/expression-of-interest/queries/PreviewStartSection';
import { CHILI_PIPER_ROUTERS, getChiliPiperCustomRouter } from 'bundles/expression-of-interest/utils/chiliPiperUtils';
import { useFocusTrap } from 'bundles/expression-of-interest/utils/dialogUtils';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';
import {
  clearActionQueryParamLocation,
  getActionQueryParam,
  getEoiContext,
  getInputfromUserProfile,
  getResponse,
  getSplitName,
  updateUserProfile,
} from 'bundles/expression-of-interest/utils/util';
import type { Phone } from 'bundles/naptimejs/resources/__generated__/ProfilesV1';
import { ProductTypes } from 'bundles/university-program-qualification/utils/constants';
import type { ChiliPiperType } from 'bundles/xddp/types';

declare global {
  interface Window {
    ChiliPiper: ChiliPiperType;
  }
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  productId: string;
  applicationUrl?: string | null;
  degreeProductVariant?: string;
  setHasSubmittedEoi?: (input: boolean) => void;
  isQualified?: boolean;
  setIsQualified?: Dispatch<SetStateAction<boolean>>;
  hasCompletedQQForm?: boolean;
  setHasCompletedQQForm?: Dispatch<SetStateAction<boolean>>;
  loadingQQUserData?: boolean;
  highestEducation?: number | null;
  setHighestEducation?: Dispatch<SetStateAction<number | null | undefined>>;
  learnerTimeline?: number | null;
  setLearnerTimeline?: Dispatch<SetStateAction<number | null | undefined>>;
  financePlan?: number | null;
  setFinancePlan?: Dispatch<SetStateAction<number | null | undefined>>;
  initialSection?: SectionName;
};

// Section management
export type SectionName =
  | 'Initial'
  | 'PhoneNumber'
  | 'Login'
  | 'Confirmation'
  | 'SelectSubject'
  // Qualifying Questions experiment
  | 'NextSteps'
  | 'QualifyingQuestions';

const styles = {
  dialog: css`
    [role='dialog'] {
      max-height: 95vh;
      ${breakpoints.up('md')} {
        width: 584px;
      }

      label {
        margin-bottom: 0;
      }

      ${breakpoints.down('xs')} {
        max-height: unset;
      }
    }
  `,
};

/**
 * Used for both old and new premium-hub DCAT experiment
 */
export const EoiDialog = (props: Props): React.ReactElement => {
  const {
    isOpen,
    onClose,
    onOpen,
    productId,
    applicationUrl,
    degreeProductVariant,
    setHasSubmittedEoi,
    isQualified,
    setIsQualified,
    hasCompletedQQForm,
    setHasCompletedQQForm,
    loadingQQUserData,
    highestEducation,
    setHighestEducation,
    learnerTimeline,
    setLearnerTimeline,
    financePlan,
    setFinancePlan,
    initialSection,
  } = props;
  const trackingProductId = productId.replace('Degree~base!~', '').replace('Degree~credentialTrack!~', '');
  const { slug, productType } = useEoiOptimizationsContext();
  const chiliPiperContext = useChiliPiper();

  const isLoggedIn = isAuthenticatedUser();

  const { trackInteractEoi } = useEoiEventing(trackingProductId);

  // Router variables
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameUrl = location.pathname || '';
  const currentUrl = `${config.url.base}${pathnameUrl.substring(1)}`;
  const eoiContext = getEoiContext(currentUrl);

  // API calls
  const [previewStartSection, { data: previewStartSectionData }] = useMutation<
    PreviewStartSectionResponse,
    PreviewStartSectionVariables
  >(PREVIEW_START_SECTION);
  const sectionId = previewStartSectionData?.QualificationSurveySectionResponse.sectionId || 'section1';
  const [createOrUpdate] = useMutation<CreateOrUpdateResponse, CreateOrUpdateVariables>(CREATE_OR_UPDATE);
  const [responseId, setResponseId] = useState<string>('');

  // Form fields
  const currentUser = userGet();
  const defaultName = getSplitName(currentUser.fullName);
  const [firstName, setFirstName] = useState<string>(defaultName.firstName || '');
  const [lastName, setLastName] = useState<string>(defaultName.lastName || '');
  const [learnerEmail, setLearnerEmail] = useState<string>(currentUser.email_address);
  const [phone, setPhone] = useState<Phone>({ phoneNumber: '' });
  const [subject, setSubject] = useState<{ categoryId: string; categoryText: string } | undefined>();
  const [isPhoneLoading, setIsPhoneLoading] = useState<boolean>(true);

  // Qualifying Questions experiment
  const qualifyingQuestionsVariant =
    productType === ProductTypes.DEGREE && applicationUrl && getQualifyingQuestionsExpt(slug);
  const isInQQVariantA = qualifyingQuestionsVariant === 'A';
  const isInQQVariantB = qualifyingQuestionsVariant === 'B';

  const [hasSkippedQQForm, setHasSkippedQQForm] = useState<boolean>(false);

  const { nextStepsDeadline } = useDegreeProgramAdmissionDeadlines(slug, !(isInQQVariantA || isInQQVariantB));

  const continueFrom = location.query.continueFrom;
  const isContinueFromLogin = continueFrom === CONTINUE_EOI_PARAMS.CONTINUE_FROM_LOGIN;

  const getInitialSection = (): SectionName => {
    if (isLoggedIn && firstName && learnerEmail) {
      if (isContinueFromLogin) {
        if (productType === ProductTypes.DEGREE_HUB) {
          return 'SelectSubject';
        }
        if (isInQQVariantA) {
          return 'NextSteps';
        }
        if (isInQQVariantB && !hasCompletedQQForm) {
          return 'QualifyingQuestions';
        }
        return 'Confirmation';
      }
      return 'PhoneNumber';
    }
    return 'Initial';
  };

  const [currentSection, setCurrentSection] = useState<SectionName>(getInitialSection());
  const [isNewSignup, setIsNewSignup] = useState<boolean>(false);

  // Disable focus lock in the CDS Dialog when displaying the signup/login form with the Arkose/reCAPTCHA challenge modal.
  const customFocusTrapSections = ['Initial', 'Login'];
  const shouldUseCustomFocusTrap = customFocusTrapSections.includes(currentSection);

  const dialogRef = useRef<HTMLDivElement>(null);

  // Trap the focus state within the dialog when the focus lock is disabled
  useFocusTrap(dialogRef, shouldUseCustomFocusTrap);

  // Open the dialog when a user comes from SSO
  useEffect(() => {
    // This can be set from an external link or from a previous login from EOI,
    const actionQueryParam = getActionQueryParam(location);
    if (actionQueryParam === ACTION_QUERY_PARAMS.EMAIL_ME_INFO) {
      onOpen();
      clearActionQueryParamLocation(location, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productId) {
      previewStartSection({ variables: { productId, input: {} } });
    }

    // Get phone number from user profile
    const fetchPhone = async () => {
      if (isLoggedIn) {
        const phoneResponse = await getInputfromUserProfile();
        let defaultPhone = { phoneNumber: '' } as Phone;
        if (phoneResponse.phoneNumber) {
          defaultPhone = phoneResponse.phoneNumber['org.coursera.survey.question.ShortAnswerResponse'].text as Phone;
        }
        setPhone(defaultPhone as Phone);
        setIsPhoneLoading(false);
        setCurrentSection(getInitialSection());
      } else {
        setIsPhoneLoading(false);
        setPhone({
          phoneNumber: '',
        });
      }
    };
    fetchPhone();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const submitResponse = (): Promise<void | {}> => {
    const responses = getResponse(firstName, lastName, learnerEmail, phone.phoneNumber, subject);
    setHasSubmittedEoi?.(true);

    if (isInQQVariantB && (highestEducation || learnerTimeline || financePlan)) {
      const qualificationStatus = isLearnerQualified(highestEducation, degreeProductVariant);
      setIsQualified?.(qualificationStatus);
      setHasCompletedQQForm?.(true);
      updateUserProfile({
        userId: userGet().id,
        externalId: userGet().external_id,
        demographics: {
          ...(highestEducation && { educationalAttainment: highestEducation }),
          ...(learnerTimeline && { degreeReadiness: learnerTimeline }),
          ...(financePlan && { financialSituation: financePlan }),
        },
      }).catch((error) => logger.error(error));
    }

    if (!firstName || !lastName) {
      return Promise.resolve();
    }

    return createOrUpdate({
      variables: {
        productId,
        shouldProcessTerminalActions: true,
        responseId: responseId ? `&responseId=${responseId}` : '',
        sectionId,
        eoiContext,
        responses,
      },
    })
      .then((response) => {
        if (response.data) {
          setResponseId(response.data.QualificationSurveySectionResponse.id);
        }

        if (productType === ProductTypes.DEGREE && responseId) {
          const chiliPiperCustomRouter = getChiliPiperCustomRouter(slug);
          const chiliPiperUserInfo = {
            FirstName: firstName,
            LastName: lastName,
            Email: learnerEmail,
            PhoneNumber: phone.phoneNumber,
          };

          const leadData: ChiliPiperInfo = {
            ...chiliPiperUserInfo,
            ProgramSlug: slug,
            SurveyResponseId: responseId,
          };

          const chiliPiperRouter = chiliPiperCustomRouter ?? CHILI_PIPER_ROUTERS.degreesEoi;
          chiliPiperContext?.submitToChiliPiper?.(leadData, chiliPiperRouter);
        }
      })
      .catch((error) => logger.error(error));
  };

  const handleClose = (eoiSection: EoiSection) => {
    trackInteractEoi('close_form', eoiSection);

    // Refresh the page for newly created accounts
    if (isNewSignup) {
      actions.onSignup(undefined, { addShowTouAcceptParam: shouldShowLegalConsolidationAuthFlow() });
      setIsNewSignup(false);
    }

    onClose();

    /*
    timeout duration is 1 ms more than the transition to close the modal which is exactly 195 ms
    see duration.leavingScreen in node_modules/@material-ui/core/styles/transitions.js to see the transition duration
    the duration is set for the CDS Modal here https://github.com/webedx-spark/cds/blob/main/packages/cds-core/src/Modal/useTransitionDuration.ts#L9
    */
    if (currentSection === 'Login') {
      setTimeout(() => {
        setCurrentSection(getInitialSection());
      }, 196);
    }
  };

  // TODO: We need to check this against the `initialSection` when this component is ready to go.
  const isStandaloneQualifyingQuestions = false;

  const SectionEventKeys: Record<SectionName, EoiSection> = {
    Initial: 'initial_section',
    PhoneNumber: 'phone_number_section',
    Confirmation: 'confirmation_section',
    Login: 'login_section',
    // Qualifying Questions
    SelectSubject: 'select_subject_section',
    NextSteps: 'next_steps',
    QualifyingQuestions: 'qualifying_questions',
  };

  const Sections: Record<SectionName, React.ReactNode> = {
    Initial: (
      <InitialSection
        handleFirstNameChange={(fName: string) => setFirstName(fName)}
        handleLastNameChange={(lName: string) => setLastName(lName)}
        handleEmailChange={(email: string) => setLearnerEmail(email)}
        handlePhoneChange={(data?: ProfilePhone) => {
          if (data?.phone) {
            const phoneNumberForEoiForm = data.phone;
            setPhone(phoneNumberForEoiForm);
          }
        }}
        firstName={firstName}
        lastName={lastName}
        learnerEmail={learnerEmail}
        phone={phone}
        handleSubmit={submitResponse}
        navigateLogin={() => setCurrentSection('Login')}
        navigateSelectSubject={() => setCurrentSection('SelectSubject')}
        navigateConfirmation={() => setCurrentSection('Confirmation')}
        trackingProductId={trackingProductId}
        setIsNewSignup={(userSignedUp) => setIsNewSignup(userSignedUp)}
        shouldSetAutoFocus={shouldUseCustomFocusTrap}
      />
    ),
    PhoneNumber: (
      <PhoneNumberSection
        phone={phone}
        isPhoneLoading={isPhoneLoading}
        handleSubmit={submitResponse} // an initial EOI is already captured before the phone number section
        navigateSelectSubject={() => setCurrentSection('SelectSubject')}
        navigateConfirmation={() => setCurrentSection('Confirmation')}
        navigateQualifyingQuestions={() => setCurrentSection('QualifyingQuestions')}
        navigateNextSteps={() => setCurrentSection('NextSteps')}
        hasCompletedQQform={hasCompletedQQForm}
        handlePhoneChange={(data?: ProfilePhone) => {
          if (data?.phone) {
            const phoneNumberForEoiForm = data.phone;
            setPhone(phoneNumberForEoiForm);
          }
        }}
        trackingProductId={trackingProductId}
      />
    ),

    Login: (
      <LoginSection
        learnerEmail={learnerEmail}
        phone={phone}
        handleEmailChange={(email: string) => setLearnerEmail(email)}
        handlePhoneChange={(data?: ProfilePhone) => {
          if (data?.phone) {
            const phoneNumberForEoiForm = data.phone;
            setPhone(phoneNumberForEoiForm);
          }
        }}
        handlePreviousSection={() => {
          setCurrentSection('Initial');
        }}
        handleSubmit={submitResponse}
        trackingProductId={trackingProductId}
        shouldSetAutoFocus={shouldUseCustomFocusTrap}
      />
    ),
    SelectSubject: (
      <SelectSubjectSection
        subject={subject}
        handleSubjectChange={(selection: { categoryId: string; categoryText: string }) => setSubject(selection)}
        handleSubmit={submitResponse}
        navigateConfirmation={() => setCurrentSection('Confirmation')}
        trackingProductId={trackingProductId}
      />
    ),
    Confirmation: (
      <ConfirmationSection
        email={learnerEmail}
        applicationUrl={applicationUrl}
        handleClose={() => handleClose('confirmation_section')}
        trackingProductId={trackingProductId}
      />
    ),
    // Qualifying Questions
    QualifyingQuestions: (
      <QualifyingQuestionsSection
        highestEducation={highestEducation}
        setHighestEducation={(education?: number | null) => setHighestEducation?.(education)}
        learnerTimeline={learnerTimeline}
        setLearnerTimeline={(timeline?: number | null) => setLearnerTimeline?.(timeline)}
        financePlan={financePlan}
        setFinancePlan={(plan?: number | null) => setFinancePlan?.(plan)}
        navigateNextSteps={() => setCurrentSection('NextSteps')}
        handleSubmit={submitResponse}
        trackingProductId={trackingProductId}
        isUserProfileLoading={loadingQQUserData}
        setHasSkipped={(hasSkipped: boolean) => setHasSkippedQQForm(hasSkipped)}
        enableSkip={initialSection !== 'QualifyingQuestions'}
      />
    ),
    NextSteps: (
      <NextStepsSection
        isQualified={isQualified}
        isStandaloneQualifyingQuestions={isStandaloneQualifyingQuestions}
        applicationUrl={applicationUrl}
        degreeDeadline={nextStepsDeadline}
        hasSkippedQQForm={hasSkippedQQForm}
        degreeProductVariant={degreeProductVariant}
        trackingProductId={trackingProductId}
      />
    ),
  };

  return (
    <Dialog
      ref={dialogRef}
      open={isOpen}
      onClose={() => handleClose(SectionEventKeys[currentSection])}
      css={styles.dialog}
      data-testid="eoi-modal"
      disableFocusLock={shouldUseCustomFocusTrap}
    >
      {initialSection && Sections[initialSection]}
      {!initialSection && Sections[currentSection]}
    </Dialog>
  );
};

export default EoiDialog;
