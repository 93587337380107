import * as React from 'react';
import { createContext, useContext } from 'react';
import type { ReactElement } from 'react';

import { ACTION_TYPES } from 'bundles/expression-of-interest/constants';
import type { EoiButtonType } from 'bundles/expression-of-interest/types';
import type { ProductTypes } from 'bundles/university-program-qualification/utils/constants';

export type ProductType = (typeof ProductTypes)[keyof typeof ProductTypes];

type EoiOptimizationsProps = {
  slug: string;
  productType?: ProductType;
  applyButtonData?: EoiButtonType | null;
};

const EoiOptimizationsContext = createContext<EoiOptimizationsProps>({
  slug: '',
  productType: undefined,
  applyButtonData: { actionType: ACTION_TYPES.apply },
});

export const useEoiOptimizationsContext = () => useContext(EoiOptimizationsContext);

type PropsToComponent = {
  children: ReactElement;
  slug: string;
  productType?: ProductType;
  applyButtonData?: EoiButtonType | null;
};

export const EoiOptimizationsContextProvider = (props: PropsToComponent) => {
  const { children, slug, productType, applyButtonData } = props;

  return (
    <EoiOptimizationsContext.Provider
      value={{
        slug,
        productType,
        applyButtonData,
      }}
    >
      {children}
    </EoiOptimizationsContext.Provider>
  );
};
