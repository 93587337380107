/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { MutableRefObject } from 'react';

import { Button, Grid, Typography, Typography2, breakpoints, useMediaQuery } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import type { SectionName } from '@coursera/event-pulse-types';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import type { Maybe } from 'bundles/cms/types';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { LearnMoreValuePropositionUnifiedFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import Container from 'bundles/premium-hub/components/shared/Container';
import DegreeCard from 'bundles/premium-hub/components/shared/DegreeCard';
import ProductCardForCourseAndS12n from 'bundles/premium-hub/components/shared/ProductCardForCourseAndS12n';
import { HubPages } from 'bundles/premium-hub/constants';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { GetCoursesBySlugsQuery } from 'bundles/premium-hub/queries/__generated__/getCoursesBySlugs';
import type { GetSpecializationsBySlugsQuery } from 'bundles/premium-hub/queries/__generated__/getSpecializationsBySlugs';
import type { PremiumProductWithMetadata, ProductCardProps, UnifiedProductCardProps } from 'bundles/premium-hub/types';
import { convertPremiumProductWithMetadataToPremiumHubProductCardProps } from 'bundles/premium-hub/utils/dataTransformationUtils';
import {
  filterNullOrUndefined,
  getCourseProductCardProps,
  getSpecializationProductCardProps,
} from 'bundles/premium-hub/utils/index';

import _t from 'i18n!nls/premium-hub';

type Props = {
  marketingContent?: Maybe<LearnMoreValuePropositionUnifiedFragment>;
  degreeListWithMetadata?: Array<PremiumProductWithMetadata>;
  relatedCourses?: GetCoursesBySlugsQuery;
  relatedS12ns?: GetSpecializationsBySlugsQuery;
  subindex: number;
  currentPageType: PremiumHubPageType;
};

const styles = {
  wrapper: css`
    width: 100%;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  `,

  container: css`
    padding: var(--cds-spacing-150);

    ${breakpoints.up('md')} {
      padding: var(--cds-spacing-300) var(--cds-spacing-150);
    }
  `,

  title: css`
    margin-bottom: var(--cds-spacing-200);
  `,

  description: css`
    margin-bottom: var(--cds-spacing-200);
  `,

  supportText: css`
    margin-bottom: var(--cds-spacing-200);
  `,

  imageWrapper: css`
    align-self: flex-start;
  `,

  image: css`
    max-width: 30%;
    margin-bottom: var(--cds-spacing-200);

    ${breakpoints.up('md')} {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  `,
  showMore: css`
    button {
      max-width: none;
    }

    & .cds-ShowMoreContainer-content {
      overflow: visible !important;
    }
  `,
  degreeList: css`
    padding: var(--cds-spacing-300, 0);

    ${breakpoints.down('sm')} {
      flex-direction: column;
    }
  `,

  productCard: css`
    height: 100%;
    width: 100%;
  `,

  titleLink: css`
    display: block;
  `,

  productCardContainer: css`
    width: 100%;
  `,

  copyText: css`
    align-self: center;
  `,

  hideOnMobile: css`
    ${breakpoints.down('xs')} {
      display: none;
    }
  `,

  hideOnDesktop: css`
    ${breakpoints.up('sm')} {
      display: none;
    }
  `,

  linkIcon: css`
    margin-left: var(--cds-spacing-100);
  `,
};

const numDegreesToDisplay = 3;

const sectionName = 'value_proposition' as SectionName;

// TODO: Add back in once https://github.com/webedx-spark/eventing-schemas/pull/202 is merged
// const getButtonName = (currentPageType: PremiumHubPageType): ButtonName => {
//   switch (currentPageType) {
//     case HubPages.MainHubPage:
//       return 'degrees_core_hub_explore';

//     case HubPages.ProductVariantHubPage:
//       return 'degrees_level_hub_explore';

//     case HubPages.DegreesCategoryHubPage:
//       return 'degrees_category_hub_explore';

//     case HubPages.LandingPage:
//       return 'premium_hub_landing_page_explore';

//     default:
//       return 'degrees_core_hub_explore';
//   }
// };

// TODO: Replace with more robust util once https://github.com/webedx-spark/web/pull/34257 is merged
// const urlToLinkType = (url: Maybe<string>): LinkType => {
//   // this first case shouldn't actually happen because if there is no url there is no link
//   if (!url) return 'internal';
//   if (url.startsWith('/')) return 'internal';
//   if (url.includes(config.url.base)) return 'internal';
//   return 'external';
// };

// TODO: Add back in once https://github.com/webedx-spark/eventing-schemas/pull/202 is merged
// const getLinkEventingV3Data = (name: ButtonName, linkURL: string, subindex: number) => ({
//   button: { name, linkType: urlToLinkType(linkURL), linkURL },
//   pageSection: { sectionName, subindex },
// });

const ValueProposition = ({
  marketingContent,
  degreeListWithMetadata,
  subindex,
  currentPageType,
  relatedCourses,
  relatedS12ns,
}: Props) => {
  const alternatingBgColorPosition = currentPageType === HubPages.ProductVariantHubPage ? 'odd' : 'even';
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  const valuePropositionRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName,
      subindex,
      index: sectionIndex,
    },
  });

  if (!marketingContent) {
    return null;
  }

  const {
    title,
    text,
    supportText,
    image,
    relatedProgramSlugs,
    relatedCourseSlugs,
    relatedSpecializationSlugs,
    callToActionText,
    callToActionUrl,
    subheading,
  } = marketingContent;

  if (!title || !text || !image) {
    return null;
  }

  const displayCta = callToActionText && callToActionUrl;

  let eligibleDegrees = degreeListWithMetadata
    ?.filter(filterNullOrUndefined)
    ?.filter((degree) => relatedProgramSlugs?.includes(degree?.slug ?? null))
    ?.map(convertPremiumProductWithMetadataToPremiumHubProductCardProps)
    ?.filter(({ name, slug }) => name && slug)
    .slice(0, numDegreesToDisplay);

  const eligibleCourses = relatedCourses?.Course?.queryBySlugs
    ?.filter(filterNullOrUndefined)
    ?.filter((course) => relatedCourseSlugs?.includes(course?.slug))
    ?.sort((a, b) => {
      const indexA = relatedCourseSlugs?.findIndex((s12nId) => s12nId === a.id) ?? Number.MAX_SAFE_INTEGER;
      const indexB = relatedCourseSlugs?.findIndex((s12nId) => s12nId === b.id) ?? Number.MAX_SAFE_INTEGER;
      if (indexA > indexB) return 1;
      if (indexA < indexB) return -1;
      return 0;
    })
    ?.map(getCourseProductCardProps)
    ?.filter(({ title: courseTitle, slug }) => courseTitle && slug);

  const eligibleS12ns = relatedS12ns?.Specialization?.queryBySlugs
    ?.filter(filterNullOrUndefined)
    ?.filter((s12n) => relatedSpecializationSlugs?.includes(s12n?.slug))
    ?.sort((a, b) => {
      const indexA = relatedSpecializationSlugs?.findIndex((s12nId) => s12nId === a.id) ?? Number.MAX_SAFE_INTEGER;
      const indexB = relatedSpecializationSlugs?.findIndex((s12nId) => s12nId === b.id) ?? Number.MAX_SAFE_INTEGER;
      if (indexA > indexB) return 1;
      if (indexA < indexB) return -1;
      return 0;
    })
    ?.map(getSpecializationProductCardProps)
    ?.filter(({ title: s12ntitle, slug }) => s12ntitle && slug);

  if (!eligibleDegrees?.length) {
    eligibleDegrees = degreeListWithMetadata
      ?.slice(0, numDegreesToDisplay)
      .map(convertPremiumProductWithMetadataToPremiumHubProductCardProps);
  }

  const eligibleProducts: UnifiedProductCardProps[] = [];
  if (eligibleCourses && eligibleCourses.length) eligibleProducts.push(...eligibleCourses);
  if (eligibleS12ns && eligibleS12ns.length) eligibleProducts.push(...eligibleS12ns);

  // TODO: Add back in once https://github.com/webedx-spark/eventing-schemas/pull/202 is merged
  // const ctaLinkEventingV3Data = getLinkEventingV3Data(getButtonName(currentPageType), callToActionUrl ?? '', subindex);

  const onValuePropLinkClick = () => {
    // TODO: Add back in once https://github.com/webedx-spark/eventing-schemas/pull/202 is merged
    // if (displayCta) trackV3('click_button', ctaLinkEventingV3Data);
  };

  return (
    <div
      ref={valuePropositionRef}
      css={[
        styles.wrapper,
        css`
          ${breakpoints.up('md')} {
            //Automatically adds the alternating gray background color
            :nth-of-type(${alternatingBgColorPosition}) {
              background-color: var(--cds-color-grey-25);
            }
          }
        `,
      ]}
    >
      <Container css={styles.container}>
        <TrackedDiv
          trackingName="learn_more_value_proposition"
          data={{ valueProposition: title }}
          withVisibilityTracking={true}
          atMostOnce={true}
          trackClicks={false}
        >
          <Grid container justifyContent="flex-start" css={styles.container} spacing={32}>
            <Grid item md={3} css={styles.copyText}>
              {subheading && (
                <Typography2
                  component="h3"
                  variant="subtitleMedium"
                  css={css`
                    margin-bottom: var(--cds-spacing-100);
                  `}
                >
                  {subheading}
                </Typography2>
              )}
              <Typography css={styles.title} component="h3" variant="h1semibold">
                {title}
              </Typography>
              {text && (
                <Typography2 component="p" css={styles.description}>
                  {text}
                </Typography2>
              )}
              {supportText && (
                <Typography2 component="p" css={styles.supportText} color="supportText" variant="bodySecondary">
                  {supportText}
                </Typography2>
              )}
              {displayCta && (
                <Button
                  css={styles.hideOnMobile}
                  variant="ghost"
                  component={TrackedLink2}
                  edgeAlign="start"
                  icon={<ArrowNextIcon />}
                  iconPosition="after"
                  trackingName={displayCta ? 'value_prop_cta_button' : 'value_proposition_explore_all_degrees_link'}
                  href={callToActionUrl}
                  linkType={displayCta ? 'auto' : 'Link'}
                  onClick={onValuePropLinkClick}
                  target={displayCta ? '_blank' : undefined}
                >
                  {displayCta ? callToActionText : _t('Explore all degrees')}
                </Button>
              )}
            </Grid>
            {eligibleProducts?.map((product, rankingIndex) => {
              if (!product.slug || !product.title) {
                return null;
              }

              return (
                <Grid key={product.id} item md={3} sm={12} css={styles.productCardContainer}>
                  <div css={styles.productCard}>
                    <ProductCardForCourseAndS12n
                      key={product.id}
                      product={product}
                      rankingIndex={rankingIndex}
                      subindex={subindex}
                      title={title}
                      sectionName={sectionName}
                      displayVariant={isSmallScreen ? 'list' : 'grid'}
                    />
                  </div>
                </Grid>
              );
            })}
            {eligibleProducts.length === 0 &&
              eligibleDegrees?.map((product, rankingIndex) => {
                if (!product.slug || !product.name) {
                  return null;
                }

                return (
                  <Grid key={product.id} item sm={12} md={3} css={styles.productCardContainer}>
                    <div css={styles.productCard}>
                      <DegreeCard
                        product={product}
                        entityIndex={rankingIndex}
                        sectionName={sectionName}
                        subindex={subindex}
                        displayVariant={isSmallScreen ? 'list' : 'grid'}
                      />
                    </div>
                  </Grid>
                );
              })}
          </Grid>
          {displayCta && (
            <Button
              fullWidth
              css={styles.hideOnDesktop}
              variant="secondary"
              component={TrackedLink2}
              edgeAlign="start"
              trackingName={displayCta ? 'value_prop_cta_button' : 'value_proposition_explore_all_degrees_link'}
              href={callToActionUrl}
              linkType={displayCta ? 'auto' : 'Link'}
              onClick={onValuePropLinkClick}
              target={displayCta ? '_blank' : undefined}
            >
              {displayCta ? callToActionText : _t('Explore all degrees')}
            </Button>
          )}
        </TrackedDiv>
      </Container>
    </div>
  );
};

export default ValueProposition;
