/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Grid, breakpoints, useMediaQuery } from '@coursera/cds-core';
import type { SectionName } from '@coursera/event-pulse-types';

import DegreeCard from 'bundles/premium-hub/components/shared/DegreeCard';
import TopProductCard from 'bundles/premium-hub/components/shared/TopProductCard';
import { ProductType } from 'bundles/premium-hub/constants';
import type { ProductCardProps } from 'bundles/premium-hub/types';
import { getUrlFromProductVariant } from 'bundles/premium-hub/utils';

type Props = {
  productsList?: Array<ProductCardProps>;
  productType: (typeof ProductType)[keyof typeof ProductType];
  sectionName: SectionName;
};

type TopProductWrapperProps = {
  product: ProductCardProps;
  productType: (typeof ProductType)[keyof typeof ProductType];
  entityIndex: number;
  sectionName: SectionName;
};

const styles = {
  container: css`
    margin-bottom: var(--cds-spacing-50);
  `,
  heading: css`
    margin-bottom: var(--cds-spacing-400);
  `,
};

const TopProductCardWrapper = (props: TopProductWrapperProps) => {
  const retrackedTrack = useRetracked();
  const { productType, ...productCardProps } = props;
  const { entityIndex, product } = productCardProps;
  const url = getUrlFromProductVariant(product.productVariant, product.slug ?? '');
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  const trackingData = {
    entityId: product.slug,
    link: url,
    ...product,
    productRankBaseZero: entityIndex,
  };
  const clickEventV2 = () =>
    retrackedTrack({
      trackingName: 'top_product_card',
      trackingData,
      action: 'click',
    });
  if (productType === ProductType.Degrees) {
    return <DegreeCard {...productCardProps} displayVariant={isSmallScreen ? 'list' : 'grid'} onClick={clickEventV2} />;
  }
  return <TopProductCard {...props} />;
};

const TopProductsList = ({ productsList, productType, sectionName }: Props) => {
  if (!productsList || !productsList.length) {
    return null;
  }

  return (
    <div data-test="TopProductsList" css={styles.container}>
      <Grid container css={{ marginTop: 'var(--cds-spacing-200)' }} data-e2e="product-list-item-container">
        {productsList.map((product, index) => {
          return (
            <Grid
              item
              lg={productType === ProductType.Degrees ? 3 : 4}
              md={productType === ProductType.Degrees ? 3 : 4}
              xs={12}
              key={product.slug}
            >
              <TopProductCardWrapper
                key={product.slug}
                product={product}
                productType={productType}
                entityIndex={index}
                sectionName={sectionName}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default TopProductsList;
