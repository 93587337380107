/**
 * For DDP Revamp Phase 3 UX epic change
 * Copy from static/bundles/authentication/shared/components/authentication-modal/SocialSSO/SocialSSO.tsx
 * Make social SSO button horizontally side by side and center button label and icon
 */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Grid, Link } from '@coursera/cds-core';

import type { ThirdPartyAuthService } from 'bundles/authentication/constants';
import { LEARNER_HELP_CENTER_LINK } from 'bundles/authentication/constants';
import Alert from 'bundles/authentication/shared/Alert';
import Apple from 'bundles/authentication/shared/Apple';
import Facebook from 'bundles/authentication/shared/Facebook';
import Google from 'bundles/authentication/shared/Google';
import type SSOError from 'bundles/authentication/shared/components/authentication-modal/SocialSSO/SSOError';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';

import _t from 'i18n!nls/authentication';

type Props = {
  onError: (error: SSOError) => void;
  onSuccess: (isRegistration: boolean) => void;
  services?: ('apple' | 'facebook' | 'google')[];
  toFocus?: boolean;
  servicesLabel?: { [K in ThirdPartyAuthService]?: string };
  initError?: string;
  trackingProductId: string;
};

const HANDLED_ERRORS = ['unableToGetProfile', 'unknownError'];

const errorMessagesFactory = () => {
  return {
    unableToGetProfile: _t('Unable to get profile. Please try again.'),
    unknownError: (
      <FormattedMessage
        message={_t(
          'Something went wrong. Please refresh the page and try again. If the problem persists, visit the {helpCenterLink}.'
        )}
        helpCenterLink={<Link href={LEARNER_HELP_CENTER_LINK}>{_t('Learner Help Center')}</Link>}
      />
    ),
  } as const;
};

const styles = {
  buttonContainer: css`
    display: flex;
    gap: var(--cds-spacing-200);

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(--cds-spacing-100);
    }

    /*
      Have to use !important to unset inline style (position: absolute) of SocialButton's icon
      so that icon and label can be centered in the button
      static/bundles/authentication/components/SSOButton.tsx
     */
    button svg {
      position: unset !important;
    }
  `,
  google: css`
    border: 1px solid var(--cds-color-neutral-primary-weak);
    border-radius: 4px;
    color: var(--cds-color-neutral-primary-weak);
    position: relative;
    width: 100%;
  `,
  facebook: css`
    border: none;
    border-radius: 4px;
    background-color: #1877f2;
    color: var(--cds-color-neutral-primary-invert);
  `,
};

const BUTTONS = {
  apple: Apple,
  facebook: Facebook,
  google: Google,
};

const STYLES = {
  apple: undefined,
  facebook: styles.facebook,
  google: styles.google,
};

const ICON_COLORS = {
  apple: undefined,
  facebook: 'var(--cds-color-neutral-primary-invert)',
  google: undefined,
};

const HorizontalSocialSSO = ({
  onError,
  onSuccess,
  services = ['google', 'facebook', 'apple'],
  toFocus,
  servicesLabel,
  initError = '',
  trackingProductId,
}: Props) => {
  const { trackInteractEoi } = useEoiEventing(trackingProductId);
  const [error, setError] = useState<string | ReactNode>(initError);
  const firstButtonRef = useRef<HTMLButtonElement>();

  const handleError = (ssoError: SSOError) => {
    if (HANDLED_ERRORS.includes(ssoError.code)) {
      // only handle close of the modal or click in reject app
      const errorMessages = errorMessagesFactory();

      // Only gets to this point when the error code is one of these types anyways
      const errorCode = ssoError.code as keyof typeof errorMessages;
      setError(errorMessages[errorCode]);
    } else {
      onError(ssoError);
    }
  };

  const handleSuccess = (isRegistration: boolean) => {
    onSuccess(isRegistration);
    trackInteractEoi(isRegistration ? 'signup' : 'login');
  };

  useEffect(() => {
    if (toFocus) {
      firstButtonRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={16}>
      {error && (
        <Grid item xs={12}>
          <Alert>{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12} css={styles.buttonContainer}>
        {services.map((service, idx) => {
          const Button = BUTTONS[service];

          return (
            <Button
              key={service}
              onError={handleError}
              onSuccess={handleSuccess}
              {...(!idx ? { forwardedRef: firstButtonRef } : {})}
              btnLabel={servicesLabel?.[service]}
              customStyles={STYLES[service]}
              iconColor={ICON_COLORS[service]}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default HorizontalSocialSSO;
