import { diffChars } from 'diff';
import flowRight from 'lodash/flowRight';

const SIMILARITY_THRESHOLD = 3;
export const blacklistedTitles = ['instructor', 'professor', 'staff', 'teacher', 'expert', 'moderator'].map((name) =>
  name.toLowerCase()
);

const normalizeNFKC = (str: string) => str.normalize('NFKC');

// This works by splitting each character with a diacritic into its components pieces, ex. Á becomes A ́, and then removing the diacritic
const removeDiacritics = (str: string) => str.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');

const toLowerCase = (str: string): string => str.toLowerCase();

const getCharDiffCount = (str1: string, str2: string) => {
  const diffs = diffChars(str1, str2);
  let diffCount = 0;

  diffs.forEach((part) => {
    if (part.added || part.removed) {
      diffCount += part.value.length;
    }
  });

  return diffCount;
};

export const hasInvalidCharacters = (name: string) => {
  const normalizedName = flowRight(normalizeNFKC, removeDiacritics, toLowerCase)(name);
  const words = normalizedName.split(/\s+/);

  for (const word of words) {
    if (blacklistedTitles.some((blacklisted) => word.includes(blacklisted))) {
      return true;
    }

    // Check for similar matches
    if (blacklistedTitles.some((blacklistedName) => getCharDiffCount(word, blacklistedName) <= SIMILARITY_THRESHOLD)) {
      return true;
    }
  }

  return false;
};
